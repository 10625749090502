import { Card, Stack, Typography } from '@mui/material'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import styles from './AxiosError.module.css'

export default function AxiosError({ message, statuscode }) {

    return (
        <Card className={styles.mainContainer} >
            <Stack direction="row" spacing={2} className={styles.stack}>
                <ErrorOutlineOutlinedIcon className={styles.icon} />
                <Typography variant="h3" className={styles.mainHeader}>ERROR</Typography>
            </Stack>
            <Stack direction="row" spacing={2} className={styles.stack}>
                <Typography variant="h5" className=''>
                    {statuscode}
                </Typography>
                <Typography variant="h5" className=''>
                    {' - '}
                </Typography>
                <Typography variant="h5" className=''>
                    {message}
                </Typography>
            </Stack>
        </Card>
    )
}