import { useState } from 'react'
import styles from './AddOneAdmin.module.css'
import useLocalization from '../../localization/useLocalization'
import { Card, Alert, FormControl, Stack, TextField, Container, Tooltip, Button, Divider } from '@mui/material'
import SubHeader from '../atoms/SubHeader'
import useCreateAdmin from '../../hooks/mutations/useCreateAdmin'

const AddOneAdmin = ({ refetch }) => {
    const localization = useLocalization()
    const createAdmin = useCreateAdmin()
    const [link, setLink] = useState()
    const initialFormValues = {
        email: '',
    }
    const [formValues, setFormValues] = useState(initialFormValues)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        createAdmin(formValues)
            .then(returnValue => {
                setLink(
                    <Tooltip title="Copy link" placement="top">
                        <Alert severity="success" className={styles.resultItem} onClick={() => copyLink(returnValue.registrationLink)}>{`${returnValue.email} ${returnValue.registrationLink}`}</Alert>
                    </Tooltip>
                )
                refetch()
            })
            .catch(error => {
                setLink(
                    <Alert severity="error" className={styles.resultItemErr}>{error?.response?.data?.errorMessage || 'Something went wrong'}</Alert>
                )
            })
            .finally(() => {
                setTimeout(() => document.getElementById('result').scrollIntoView({ behavior: 'smooth' }), 100)

            })
        clearForm()
    }

    const clearForm = () => {
        setFormValues(initialFormValues)
    }

    const copyLink = (text) => {
        navigator.clipboard.writeText(text)
    }

    return (
        <Card className={styles.mainContainer}>
            <SubHeader headingText={localization.addAdmin} variant={'h6'} />
            <form id="form" onSubmit={handleSubmit} autoComplete="off" className={styles.secondaryContainer}>
                <Stack direction="column" spacing={2} sx={{ marginTop: '1em' }}>
                    <FormControl>
                        <TextField name="email" type="email" value={formValues.email} label={localization.emailPlaceHolder} fullWidth onChange={handleChange} required />
                    </FormControl>
                    <Stack direction="row" spacing={2} >
                        <Button variant="contained" color="primary" type="submit" sx={{ width: '100%'  }}>
                            { localization.fillFormButton }
                        </Button>
                        <Button variant="outlined" type="button" onClick={() => clearForm()} sx={{ width: '100%'  }}>{localization.clearButton}</Button>
                    </Stack>
                </Stack>
            </form>
            { link && (
                <Container id="result" className={styles.resultContainer}>
                    <SubHeader headingText={localization.results} variant={'h6'} />
                    <Divider variant="middle" sx={{ marginTop: '10px' }} />
                    {link}
                </Container>
            )}
        </Card>
    )
}

export default AddOneAdmin