import { HexColorPicker } from 'react-colorful'
import { IconButton, Stack, TextField } from '@mui/material'
import styles from './ColorPicker.module.css'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import { useState } from 'react'
import useLocalization from '../../localization/useLocalization'

const ColorPicker = ({ value, onChange }) => {
    const [showPicker, setShowPicker] = useState(false)
    const localization = useLocalization()
    const error = value?.length > 0 && !/^#([0-9A-F]{3}){1,2}$/i.test(value)

    return (
        <Stack direction="row" spacing={2} className={styles.container}>
            { value && value.length !== 0 && <div className={styles.sampleBox} style={{ backgroundColor: value }}/> }
            <TextField value={value} onChange={(e) => onChange(e.target.value)} label={localization.labelColorPicker} error={error} helperText={ error? localization.hexColorFormatOnly : '' }/>
            <IconButton className={styles.icon} onClick={() => setShowPicker(!showPicker)}>
                <ColorLensIcon />
            </IconButton>
            {showPicker && <HexColorPicker color={value} onChange={onChange} /> }
        </Stack>
    )
}

export default ColorPicker