import { Navigate, useLocation } from 'react-router-dom'
import useIsAdmin from '../../hooks/user/useIsAdmin'

const RequireAdmin = ({ element }) => {
    const isAdmin = useIsAdmin()
    const location = useLocation()
    if (!isAdmin) return <Navigate to="/login" state={{ from: location }} replace />

    return element
}

export default RequireAdmin

