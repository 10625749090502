import { useEffect, useState } from 'react'
import styles from './AddOneQuestion.module.css'
import useLocalization from '../../localization/useLocalization'
import { Card, Alert, FormControl, Snackbar, Stack, TextField, Button } from '@mui/material'
import LoadingButton from '../atoms/LoadingButton'
import SelectStudentAssociation from '../molecules/SelectStudentAssociation'
import { alertLength } from '../../constants'
import { supportedScales } from '../../scaleSetup'
import useAddOneQuestion from '../../hooks/mutations/useAddOneQuestion'
import SubHeader from '../atoms/SubHeader'
import useEditQuestion from '../../hooks/mutations/useEditQuestion'
import useGetStudentAssociations from '../../hooks/queries/useGetStudentAssociations'

const AddOneQuestion = ({ data, refetch, edited, setEdited }) => {
    const localization = useLocalization()
    const { data: studentAssociationData, error: studentAssociationError, loading: studentAssociationLoading } = useGetStudentAssociations()

    const [showSnack, setShowSnack] = useState('')
    const [formValues, setFormValues] = useState({
        id: '',
        questionFin: '',
        questionSwe: '',
        questionEng: '',
        scale: '5',
        sequence: '1',
        studentAssociation: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        
        if(name === 'studentAssociation') {
            let seqs = []
            data.map((item) => {
                if(item.studentAssociation._id === value) {
                    seqs.push(item.sequence)
                }
            })

            if (seqs.length === 0) seqs.push(0)
            
            setFormValues({
                ...formValues,
                [name]: value,
                sequence: (Math.max(...seqs) + 1).toString(),
            })
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            })
        }
    }

    const clearForm = (empty) => {
        if (empty) {
            setFormValues({
                id: '',
                questionFin: '',
                questionSwe: '',
                questionEng: '',
                scale: '5',
                sequence: '1',
                studentAssociation: ''
            })
        } else {
            setFormValues({
                id: '',
                questionFin: '',
                questionSwe: '',
                questionEng: '',
                scale: '5',
                sequence: (parseInt(formValues.sequence) + 1).toString() || '',
                studentAssociation: formValues.studentAssociation
            })
        }
        setEdited(null)
    }

    const validateScale = () => {
        const sc = formValues.scale

        if(sc && supportedScales.includes(parseInt(sc)) || sc === '') return false
        return true
    }

    const validateSequence = () => {
        const seq = parseInt(formValues.sequence) || null
        const sa = formValues.studentAssociation || null

        if(sa && seq) {
            const found = data.find(item => item.studentAssociation?._id === sa && item.sequence === seq)
            if(!found) return false
            if(edited && found && found?._id === formValues.id) return false
            return true
        }
        return false
    }

    useEffect(() => {
        if(edited) {
            setFormValues({
                id: edited._id || '',
                questionFin: edited.questionFin || '',
                questionSwe: edited.questionSwe || '',
                questionEng: edited.questionEng || '',
                scale: edited.scale || '5',
                sequence: edited.sequence || '',
                studentAssociation: edited.studentAssociation?._id || ''
            })

            window.scrollTo({
                top: 750,
                left: 0,
                behavior: 'smooth'
            })
        }
    }, [edited])

    const handleSubmit = (e) => {
        e.preventDefault()
        
        if(!edited) {
            useAddOneQuestion(formValues).then(snackValue => {
                setShowSnack(snackValue)
                refetch()
                clearForm()
            })
        } else {
            useEditQuestion(formValues).then(snackValue => {
                setShowSnack(snackValue)
                refetch()
                clearForm(true)
            })
        }
    }

    return (
        <Card className={styles.mainContainer}>
            <SubHeader headingText={!edited ? localization.addQuestion : localization.editQuestion} variant={'h6'} />
            <form onSubmit={handleSubmit} autoComplete="off" className={styles.secondaryContainer}>
                <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => setShowSnack('')}>
                    <Alert severity="success">{localization.profileUpdateSuccess}</Alert>
                </Snackbar>
                <Snackbar autoHideDuration={alertLength} open={showSnack === 'error'} onClose={() => setShowSnack('')}>
                    <Alert severity="error">{localization.profileUpdateError}</Alert>
                </Snackbar>
                <Stack direction="column" spacing={2} sx={{ marginTop: '1em' }}>
                    <FormControl sx={{ display: 'none' }}>
                        <TextField name="id" value={formValues.id} />
                    </FormControl>
                    <SelectStudentAssociation data={studentAssociationData} loading={studentAssociationLoading} error={studentAssociationError} name="studentAssociation" value={formValues.studentAssociation} onChange={ (_id, _sa, e) => handleChange(e) }/>
                    <FormControl>
                        <TextField name="questionFin" multiline value={formValues.questionFin} label={localization.questionFin} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="questionSwe" multiline value={formValues.questionSwe} label={localization.questionSwe} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="questionEng" multiline value={formValues.questionEng} label={localization.questionEng} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="scale" value={formValues.scale} label={localization.scale} error={validateScale()} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="sequence" value={formValues.sequence} label={localization.sequence} error={validateSequence()} fullWidth onChange={handleChange} />
                    </FormControl>
                    <Stack direction="row" spacing={2} >
                        <LoadingButton loading={false} variant="contained" color="primary" type="submit" sx={{ width: '100%'  }}>
                            { !edited ? localization.fillFormButton : localization.updateButton }
                        </LoadingButton>
                        <Button variant="outlined" type="button" onClick={() => clearForm(true)} sx={{ width: '100%'  }}>{edited ? localization.cancelButton : localization.clearButton}</Button>
                    </Stack>
                </Stack>
            </form>
        </Card>
    )
}

export default AddOneQuestion