import { decodeJwt } from 'jose'
import { userToken } from '../constants'
import setUserJWT from './setUserJWT'

const refreshUserJWT = () => {
    const userT = sessionStorage.getItem(userToken)
    if (!userT) return null
    const decoded = decodeJwt(userT)
    setUserJWT(decoded)
}

export default refreshUserJWT