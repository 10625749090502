import { userToken } from '../../constants'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import useChangeTheme from '../useChangeTheme'

const useLogout = () => {
    const navigate = useNavigate()
    const changeTheme = useChangeTheme()
    const logout = () => {
        sessionStorage.removeItem(userToken)
        axios.get(`${process.env.REACT_APP_API_HOST}/logout`, { withCredentials: true })
            .then(() => {
                changeTheme()
                navigate('/')
            })
    }

    return logout
}

export default useLogout