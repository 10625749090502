const language = {
    title: 'Vaalikone',
    homePageDescription: 'Tervetuloa käyttämään vaalikonetta!',
    homePageInfo: 'Aloita vaalikoneen käyttäminen valitsemalla opiskelijakuntasi',
    selectStudentAssociation: 'Opiskelijakuntasi',
    navigationHome: 'Koti',
    login: 'Kirjaudu sisään',
    navigationRegister: 'Rekisteröidy',
    navigationLogOut: 'Kirjaudu ulos',
    navigationProfile: 'Profiili',
    formButton: 'Kysely',
    loginButton: 'Kirjaudu',
    loginHolder: 'Olet onnistuneesti kirjautunut sisään!',
    mainPageLink: 'Takaisin etusivulle',
    registerButton: 'Rekisteröidy',
    emailPlaceHolder: 'Sähköpostiosoite',
    passwordPlaceHolder: 'Salasana',
    passwordConfirmationPlaceHolder: 'Salasanan varmennus',
    registrationError: 'Et voi rekisteröityä sellaisella sähköpostiosoitteella, jota ei ole lisätty tietokantaan kandidaattina!',
    passwordConfirmationError: 'Varmennetun salasanan täytyy täsmätä salasanan kanssa',
    profileButton: 'Vahvista tiedot',
    adminLogo: 'Ylläpitäjän paneeli',
    addCandidatesCautionHolder: 'Lisätään kandidaatit, jotka eivät ole tietokannassa',
    profileUpdateError: 'Profiilin päivitys epäonnistui',
    profileUpdateSuccess: 'Olet onnistuneesti päivittänyt profiilisi tiedot',
    fillFormButton: 'Lähetä',
    continueHolder: 'Jatka',
    browseButton: 'Selaa',
    uploadPicture: 'Lataa profiilikuva',
    candidateHolder: 'Kandidaatti',
    successQuestionAddedHolder: 'Olet onnistuneesti lisännyt uuden kysymyksen',
    wrongPasswordHolder: 'Väärä käyttäjätunnus tai salasana',
    addedCandidatesToDatabaseAlert: 'Kandidaatit onnistuneesti lisätty tietokantaan',
    addingOneCandidateToDatabaseAlert: 'Kandidaatti onnistuneesti lisätty tietokantaan',
    addingOneCandidateToDatabaseErrorAlert: 'Kandidaatti tällä sähköpostiosoitteella on jo olemassa',
    filledFormAlert: 'Olet onnistuneesti täyttänyt kyselyn',
    oneCandidate: 'Täytä alla olevat tiedot lisätäksesi yhden kandidaatin:',
    manyCandidates: 'Voit lisätä useamman kandidaatin alla olevan linkin kautta. Vain .csv/.txt tiedostot käyvät. ',
    addCandidates: 'Lisää monta kandidaattia',
    addCandidate: 'Lisää yksi kandidaatti',
    addAdmin: 'Lisää ylläpitäjä',
    browseCandidates: 'Selaa kandidaatteja',
    addQuestion: 'Lisää kysymys',
    browseQuestions: 'Selaa kysymyksiä',
    removeButton: 'Poista',
    agreeButton: 'Samaa mieltä',
    disagreeButton: 'Eri mieltä',
    matchingCandidates: 'Saman näkemyksen omaavat kandit:',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    school: 'Koulu',
    selectStudentAssociationList: 'Opiskelijakunta',
    campus: 'Kampus',
    electoralDistrict: 'Vaalipiirit',
    electoralAlliance: 'Vaaliliitto',
    description: 'Kuvaus',
    addQuestionButton: 'Lisää kysymys',
    deleteButton: 'Poista',
    editButton: 'Muokkaa',
    updateButton: 'Päivitä tiedot',
    formLink: 'Linkki täytettyyn kyselyyn',
    studentAssociationHolder: 'Opiskelijakunta',
    deleteHolder: 'Poista',
    questionHolder: 'Kysymys:',
    noSimilarCandidates: 'Valitettavasti kukaan ei ollut samaa mieltä kanssasi',
    editStudentAssociation: 'Muokkaa opiskelijakuntaa',
    createStudentAssociation: 'Lisää opiskelijakunta',
    createNew: 'Lisää uusi',
    studentAssociationSuccess: 'Opiskelijakunnan käsittely onnistui',
    studentAssociationError: 'Opiskelijakunnan käsittely epäonnistui!',
    answerUpdateSuccess: 'Vastausten päivittäminen onnistui',
    answerUpdateError: 'Vastausten päivittäminen epäonnistui!',
    agree: 'Samaa mieltä',
    neutral: 'En osaa sanoa',
    disagree: 'Eri mieltä',
    clearButton: 'Tyhjennä',
    cancelButton: 'Peruuta',
    candidates: 'Kandidaatit',
    deleteSelected: 'Poista valitut',
    editSelected: 'Muokkaa valittua',
    editSuccess: 'Tietojen päivittäminen onnistui',
    editError: 'Tietojen päivittämisessä tapahtui virhe!',
    candidateControls: 'Kandidaattien hallinta',
    schoolControls: 'Koulutietojen hallinta',
    questionControls: 'Kysymyksien hallinta',
    adminControls: 'Ylläpitäjien hallinta',
    studentAssociations: 'Opiskelijakunnat',
    questions: 'Kysymykset',
    scale: 'Vaihtoehtomäärä',
    sequence: 'Järjestys',
    editCandidate: 'Muokkaa kandidaattia',
    editQuestion: 'Muokkaa kysymystä',
    results: 'Tulokset',
    copyLink: 'Kopio linkki',
    selectFileToUpload: 'Valitse tiedosto',
    questionFin: 'Kysymys suomeksi',
    questionEng: 'Kysymys englanniksi',
    questionSwe: 'Kysymys ruotsiksi',
    itemDeleteSuccess: 'Valitut kohteet poistettu onnistuneesti',
    itemDeleteError: ' Ongelmaa valittujen kohteiden poistamisessa!',
    resetPassword: 'Aseta uusi salasana',
    resetCandidatePassword: 'Alusta kandidaatin salasana',
    candidate: 'Kandidaatti',
    you: 'Sinä',
    profileFillWarning: 'Profiilista puuttuu olennaisia tietoja',
    imageUploadFailed: 'Kuvan tallennus epäonnistui',
    fileUploadPrompt: 'Vedä kuva tähän tai klikkaa tästä valitaksesi kuvan',
    uploadErrorSupportedFormats: 'Kuvan lähetys epäonnistui. Kuvan tulee olla .jpeg, .jpg, .gif tai .png',
    answerQuestions: 'Vastaa kyselyyn',
    fillRequiredBeforeAnswering: 'Täytä pakolliset profiilitiedot ennenkuin voit vastata kysymyksiin',
    profileRequiredMissingRedirect: 'Sinut ohjattiin takaisin profiiliin, koska osa pakollisista kentistä on vielä täyttämättä',
    returnToQuestions: 'Palaa kyselyyn',
    returnToFront: 'Palaa etusivulle',
    returnToProfile: 'Palaa profiilisivulle',
    answerAndSend: 'Vastaa kysymyksiin ja lähetä lomake',
    labelColorPicker: 'Teemaväri (hex)',
    studentAssociationLogoLabel: 'Oppilaskunnan logo',
    studentAssociationNameLabel: 'Oppilaskunnan nimi',
    studentAssociationColorLabel: 'Oppilaskunnan teemaväri',
    hexColorFormatOnly: 'Värin tulee olla hex formaatissa',
    noElectoralDistrictSet: 'Vaalipiiriä ei valittu',
    profilePictureLabel: 'Profiilikuva',
    admins: 'Ylläpitäjät',
    passwordResetFailed: 'Salasanan päivitys epäonnistui',
    resetCandidateImage: 'Alusta profiilikuva',
    profileImageUploaded: 'Profiilikuvan lataus onnistui!',
}

export default language