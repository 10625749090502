import { useState, useEffect } from 'react'
import styles from './StudentAssociationTable.module.css'
import useLocalization from '../../localization/useLocalization'
import { DataGrid } from '@mui/x-data-grid'
import { Card, Container, CircularProgress, Button, Stack, Typography, Snackbar, Alert } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import useDeleteStudentAssociation from '../../hooks/mutations/useDeleteStudentAssociation'
import AxiosError from '../molecules/AxiosError'
import SubHeader from '../atoms/SubHeader'
import { alertLength } from '../../constants'

const StudentAssociationTable = ({ data, error, loading, refetch, setEdited }) => {
    const localization = useLocalization()
    const [rows, setRows] = useState([])
    const [selectionModel, setSelectionModel] = useState([])
    const [showSnack, setShowSnack] = useState('')
    const [failed, setFailed] = useState('')
    
    const columns = [
        { field: 'name', headerName: localization.studentAssociationHolder, width: 200 },
        { field: 'electoralDistricts', headerName: localization.electoralDistrict, width: 400 }
    ]

    const handleDelete = () => {
        if(selectionModel.length === 0) return

        const promiseArr = selectionModel.map(school => {
            return useDeleteStudentAssociation(school)
        })
        
        Promise.all(promiseArr).then((arr) => {
            const fails = (arr.filter(x => { return x === 'error' })).length
            refetch()
            if (!fails) {
                return setShowSnack('success')
            }
            setFailed(fails + '/' + arr.length)
            setShowSnack('error')
        }).catch(() => {
            setShowSnack('error')
        })
    }

    const handleEdit = () => {
        if(selectionModel.length === 0) return
        if(selectionModel.length > 1) return

        const selected = data.find(item => item._id === selectionModel[0])
        setEdited(selected)
    }

    useEffect(() => {
        if (!data) return

        let arr = []
        data.map(item => {
            let newItem = {
                id: item._id,
                name: item.name,
                electoralDistricts: item.electoralDistricts ? item.electoralDistricts?.map(ed => ' ' + ed.name) : []
            }
            arr.push(newItem)
        })
        setRows(arr)
    }, [data])

    if (loading) return <CircularProgress size={28} sx={{ marginTop: '1em' }} />
    if (error) return <AxiosError message={error.response.data} statuscode={error.response.status} />

    return (
        <Card className={styles.mainContainer} >
            <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => setShowSnack('')}>
                <Alert severity="success">{localization.itemDeleteSuccess}</Alert>
            </Snackbar>
            <Snackbar autoHideDuration={alertLength * 2} open={showSnack === 'error'} onClose={() => setShowSnack('')}>
                <Alert severity="error">{failed + localization.itemDeleteError}</Alert>
            </Snackbar>
            <SubHeader headingText={localization.studentAssociations} variant={'h6'} />
            <Container className={styles.secondaryContainer}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel)
                    }}
                    selectionModel={selectionModel}
                />
            </Container>
            <Container className={styles.deleteBtn}>
                <Button variant='outlined' color='error' type='button' onClick={() => handleDelete()}><Stack direction="row" ><DeleteIcon /><Typography sx={{ paddingTop: '2px' }}>{localization.deleteSelected}</Typography></Stack></Button>
                <Button variant='outlined' color='info' type='button' onClick={() => handleEdit()} sx={{ marginLeft: '5px' }}><Stack direction="row"><EditIcon /><Typography sx={{ paddingTop: '2px' }}>{localization.editSelected}</Typography></Stack></Button>
            </Container> 
        </Card>
    )
}

export default StudentAssociationTable