import { Alert, Button, Card, Container, Snackbar, Stack } from '@mui/material'
import UserQuestionItem from './UserQuestionItem'
import CandidateQuestionItem from './CandidateQuestionItem'
import { alertLength } from '../../constants'
import useIsLoggedIn from '../../hooks/user/useIsLoggedIn'
import { useNavigate } from 'react-router-dom'
import useLocalization from '../../localization/useLocalization'
import styles from './QuestionItemList.module.css'
import { useState } from 'react'
import useAnswerQuestions from '../../hooks/mutations/useAnswerQuestions'

const QuestionItemList = ({ data, userAnswers, handleChange, setReady }) => {
    const localization = useLocalization()
    const isLoggedIn = useIsLoggedIn()
    const navigate = useNavigate()
    const [showSnack, setShowSnack] = useState('')
    const answerQuestionsMutation = useAnswerQuestions()

    const handleSubmit = () => {
        answerQuestionsMutation(userAnswers)
            .then((result) => setShowSnack(result))
            .catch((error) => setShowSnack(error))
    }

    return (
        <>
            { 
                !isLoggedIn ? (
                    <Container className={styles.container}>
                        {
                            data.map(questionItem => {
                                const userAnswer = userAnswers.find(a => a.id === questionItem._id)

                                return (
                                    <UserQuestionItem key={questionItem._id} question={questionItem} handleChange={handleChange} value={userAnswer.value} />
                                )
                            })
                        }
                        <Card className={styles.buttonCard}>
                            <Stack direction='row' spacing={5} className={styles.buttonRow}>
                                <Button variant="contained" onClick={() => setReady(true)} className={styles.btn}>{localization.fillFormButton}</Button>
                                <Button variant="outlined" onClick={() => navigate('/')} className={styles.btn}>{localization.returnToFront}</Button>
                            </Stack>
                        </Card>
                    </Container>
                ) : (
                    <Container className={styles.container}>
                        {
                            data.map(questionItem => {
                                const userAnswer = userAnswers.find(a => a.id === questionItem._id)
                                return (
                                    <CandidateQuestionItem key={questionItem._id} question={questionItem} handleChange={handleChange} value={userAnswer?.value} description={userAnswer?.description} />
                                )
                            })
                        }
                        <Card className={styles.buttonCard}>
                            <Stack direction='row' spacing={5} className={styles.buttonRow}>
                                <Button variant="contained" onClick={() => handleSubmit()} className={styles.btn}>{localization.fillFormButton}</Button>
                                <Button variant="outlined" onClick={() => navigate('/profile')} className={styles.btn}>{localization.returnToProfile}</Button>
                            </Stack>
                        </Card>
                        <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => setShowSnack('')}>
                            <Alert severity="success">{localization.answerUpdateSuccess}</Alert>
                        </Snackbar>
                        <Snackbar autoHideDuration={alertLength} open={showSnack === 'error'} onClose={() => setShowSnack('')}>
                            <Alert severity="error">{localization.answerUpdateError}</Alert>
                        </Snackbar>
                    </Container>    
                )}
        </>
    )
}

export default QuestionItemList