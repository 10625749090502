import { FormControl, IconButton, ListItem, TextField } from '@mui/material'
import useLocalization from '../../localization/useLocalization'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'

const ElectoralDistrictPicker = ({ onChange, value }) => {
    const localization = useLocalization()

    const onCreateNew = () => {
        onChange([...value, { name: 'Uusi', _id: value.length }])
    }

    const onEdit = (val, id) => {
        const newValue = [...value]
        const dist = newValue.find((district) => district._id === id)
        dist.name = val
        onChange(newValue)
    }

    const onDelete = (id) => {
        const updatedValue = value.filter(v => v._id !== id)
        onChange(updatedValue)
    }

    return (
        <FormControl fullWidth>
            <List sx={{ width: '30%' }}>
                { value.map((district) => {
                    return (
                        <ListItem
                            sx={{ paddingLeft: '0px' }}
                            key={district._id }
                            secondaryAction={
                                <IconButton edge="end" onClick={() => onDelete(district._id)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <TextField value={district.name} onChange={(e) => onEdit(e.target.value, district._id)} />
                        </ListItem>
                    )
                })}
            </List>
            <Button sx={{ width: '20%' }} variant="contained" onClick={onCreateNew}>{localization.createNew}</Button>
        </FormControl>
    )
}

export default ElectoralDistrictPicker