import styles from './Profile.module.css'
import HeadingBox from '../components/atoms/HeadingBox'
import ProfileForm from '../components/organisms/ProfileForm'
import { Alert, Card, Container, Stack } from '@mui/material'
import useLocalization from '../localization/useLocalization'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

const ProfilePage = () => {
    const localization = useLocalization()
    const location = useLocation()
    const [showWarning, setShowWarning] = useState(false)

    return (
        <Container className={styles.centerContainer}>
            <Card className={styles.customContainer}>
                <HeadingBox headingText={'Profile'} variant={'h6'} />
                { showWarning && (
                    <Stack spacing={2}>
                        <Alert severity="warning">{localization.profileFillWarning}</Alert>
                        { location.state?.profileMissingRequiredRedirect && <Alert severity="error">{localization.profileRequiredMissingRedirect}</Alert> }
                    </Stack>
                )}
                <ProfileForm setShowWarning={setShowWarning}/>
            </Card>
        </Container>
    )
}

export default ProfilePage