const language = {
    title: 'VotingAid',
    homePageDescription: 'Welcome to our voting aid application!',
    homePageInfo: 'Start by using your student union',
    selectStudentAssociation: 'Your student union',
    navigationHome: 'Home',
    login: 'Login',
    navigationRegister: 'Register',
    navigationLogOut: 'Logout',
    navigationProfile: 'Profile',
    formButton: 'Form',
    loginButton: 'Login',
    loginHolder: 'You have succesfully logged in',
    mainPageLink: 'Back to main page',
    registerButton: 'Register',
    emailPlaceHolder: 'Email',
    passwordPlaceHolder: 'Password',
    passwordConfirmationPlaceHolder: 'Password confirmation',
    registrationError: 'You cannot register an account for email address that does not exist as a candidate!',
    passwordConfirmationError: 'Confirmation password must match with password',
    profileButton: 'Submit Information',
    adminLogo: 'Admin Panel' ,
    addCandidatesCautionHolder: 'This will add all the candidates, which are not present in the database' ,
    profileUpdateError: 'Updating profile failed',
    profileUpdateSuccess: 'You have Succesfully updated your profile data',
    fillFormButton: 'Submit',
    continueHolder: 'Proceed' ,
    browseButton: 'Browse file' ,
    uploadPicture: 'Upload a profile picture',
    candidateHolder: 'Candidate',
    successQuestionAddedHolder: 'You have succesfully submitted a question"' ,
    wrongPasswordHolder: 'Invalid Username Or Password',
    addedCandidatesToDatabaseAlert: 'Candidates have been added into the database',
    addingOneCandidateToDatabaseAlert: 'Succesfully added user to database',
    addingOneCandidateToDatabaseErrorAlert: 'Email with this address already exists as a user',
    filledFormAlert: 'You have succesfully filled the form',
    oneCandidate: 'Fill in the information below to add one candidate:',
    manyCandidates: 'You can add candidates by the link below. Only .csv/.txt files are acceptable.',
    addCandidates: 'Add Candidates',
    addCandidate: 'Add One Candidate',
    addAdmin: 'PUUTTUU',
    browseCandidates: 'Browse Candidates',
    addQuestion: 'Add Question',
    browseQuestions: 'Browse Questions',
    removeButton: 'Remove',
    agreeButton: 'Agree',
    disagreeButton: 'Disagree',
    matchingCandidates: 'Candidates with matching values:',
    firstName: 'First name:',
    lastName: 'Surname',
    school: 'School',
    selectStudentAssociationList: 'Student Association',
    campus: 'Campus',
    electoralDistrict: 'Electoral District',
    electoralAlliance: 'Electoral Alliance',
    description: 'Description',
    addQuestionButton: 'Add question',
    deleteButton: 'Delete',
    editButton: 'Edit',
    updateButton: 'Update your information',
    formLink: 'Link to your filled form',
    studentAssociationHolder: 'Student Association',
    deleteHolder: 'Delete',
    questionHolder: 'Question:',
    noSimilarCandidates: 'Unfortunately no one related with your answers',
    editStudentAssociation: 'Edit Student Union',
    createStudentAssociation: 'Add Student Union',
    createNew: 'Add new',
    studentAssociationSuccess: 'Processing the Student Union was successful',
    studentAssociationError: 'Failed to process the Student Union!',
    answerUpdateSuccess: 'Answers were updated successfully',
    answerUpdateError: 'Failed to update answers!',
    agree: 'Agree',
    neutral: 'I can not say',
    disagree: 'Disagree',
    clearButton: 'Empty',
    cancelButton: 'Cancel',
    candidates: 'Candidates',
    deleteSelected: 'Delete selected',
    editSelected: 'Edit selected',
    editSuccess: 'Data was updated successfully',
    editError: 'An error occurred while updating the data!',
    candidateControls: 'Candidate Management',
    schoolControls: 'UAS information management',
    questionControls: 'Question Management',
    adminControls: 'Administrator Management',
    studentAssociations: 'Student Unions',
    questions: 'Questions',
    scale: 'Number of options',
    sequence: 'Order',
    editCandidate: 'Edit candidate',
    editQuestion: 'Edit question',
    results: 'Results',
    copyLink: 'Copy link',
    selectFileToUpload: 'Choose file',
    questionFin: 'Question in Finnish',
    questionEng: 'Question in English',
    questionSwe: 'Question in Swedish',
    itemDeleteSuccess: 'Selected items deleted successfully',
    itemDeleteError: ' Problem deleting selected items!',
    resetPassword: 'Enter new password',
    resetCandidatePassword: 'Enter password for candidate',
    candidate: 'Candidate',
    you: 'You',
    profileFillWarning: 'The profile is missing important information',
    imageUploadFailed: 'Failed to save image',
    fileUploadPrompt: 'Drag an image here or click here to select an image',
    uploadErrorSupportedFormats: 'Image upload failed. Image must be .jpeg, .jpg, .gif or .png',
    answerQuestions: 'Answer the survey',
    fillRequiredBeforeAnswering: 'Fill in the required profile information before you can answer the questions',
    profileRequiredMissingRedirect: 'You were redirected back to your profile because some of the required fields are not yet filled in',
    returnToQuestions: 'Return to survey',
    returnToFront: 'Return to the front page',
    returnToProfile: 'Return to profile page',
    answerAndSend: 'Answer the questions and submit the form',
    labelColorPicker: 'Theme color (hex)',
    studentAssociationLogoLabel: 'Student Union logo',
    studentAssociationNameLabel: 'Student Union name',
    studentAssociationColorLabel: 'Student Union theme color',
    hexColorFormatOnly: 'Color must be in hex format',
    noElectoralDistrictSet: 'Electoral district not selected',
    profilePictureLabel: 'Profile picture',
    admins: 'Administrators',
    passwordResetFailed: 'Password reset failed',
    resetCandidateImage: 'PUUTTUU',
    profileImageUploaded: 'PUUTTUU',
}

export default language