import axios from 'axios'

const useAnswerQuestions = () => {

    const answerMutation = (values) => {
        return axios.post(`${process.env.REACT_APP_API_HOST}/answerQuestions`, { data: values }, { withCredentials: true })
            .then(res => {
                if (res.status !== 200) return 'error'

                return 'success'
            })
            .catch(() => {
                return 'error'
            })
    }

    return answerMutation
}

export default useAnswerQuestions