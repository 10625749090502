import { FormControl, Grid, MenuItem } from '@mui/material'
import { languages, languageToken } from '../../constants'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SelectBorderless from '../atoms/SelectBorderless'
import styles from './LanguagePicker.module.css'

const LanguagePicker = () => {
    const chosenLanguage = sessionStorage.getItem(languageToken)
    const [selectedLanguage, setSelectedLanguage] = useState(chosenLanguage ? chosenLanguage : languages.fin)
    const navigate = useNavigate()

    const finlandFlag = '/finland.svg'
    const swedenFlag = '/sweden.svg'
    const ukFlag = '/uk.svg'

    const handleChange = (event) => {
        const { value } = event.target
        setSelectedLanguage(value)
        sessionStorage.setItem(languageToken, value)
        navigate(0)
    }

    const showFlag = (value) => {
        switch(value) {
        case languages.fin:
            return <img className={styles.flag} src={finlandFlag} />
        case languages.swe:
            return <img className={styles.flag} src={swedenFlag} />
        case languages.eng:
            return <img className={styles.flag} src={ukFlag} />
        }
    }

    const createMenuItem = (value) => {
        const texts = {
            [languages.fin]: 'Suomi',
            [languages.swe]: 'Sverige',
            [languages.eng]: 'English'
        }
        return (
            <MenuItem value={value} >
                <Grid container columnSpacing={1}>
                    <Grid item xs={4}>
                        {showFlag(value)}
                    </Grid>
                    <Grid item xs={8} className={styles.centered}>
                        {texts[value]}
                    </Grid>
                </Grid>
            </MenuItem>
        )
    }

    return (
        <FormControl>
            <SelectBorderless value={selectedLanguage} onChange={handleChange} renderValue={showFlag}>
                {createMenuItem(languages.fin)}
                {createMenuItem(languages.swe)}
                {createMenuItem(languages.eng)}
            </SelectBorderless>
        </FormControl>

    )
}

export default LanguagePicker