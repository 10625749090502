import { useState, useEffect } from 'react'
import axios from 'axios'

const useGetSuggestions = (studentAssociation, userAnswers) => {
    const [data, setData] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_HOST}/suggested`, { studentAssociation, userAnswers }, { withCredentials: true })
            .then(res => {
                if (res.data) return setData(res.data)
                throw new Error()
            })
            .catch(err => {
                if (err?.response?.data) return setError(err.response.data)
                setError('Something went wrong...')
            })
    }, [studentAssociation, userAnswers])
    
    return { data, error, loading: !error && !data }
}

export default useGetSuggestions