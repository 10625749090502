import axios from 'axios'

const useResetation = (values) => {

    return axios.post(`${process.env.REACT_APP_API_HOST}/reset`, { password: values.password, email: values.email, id: values.id })
        .then(res => {
            if (res.status !== 200) return 'error'
                
            return 'success'
        })
        .catch(() => {
            return 'error'
        })

}

export default useResetation