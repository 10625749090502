import { Button, Card } from '@mui/material'
import styles from './SuggestionList.module.css'
import SelectElectoralDistrict from './SelectElectoralDistrict'
import SuggestionTable from '../organisms/SuggestionTable'
import { useState } from 'react'
import useLocalization from '../../localization/useLocalization'

const SuggestionList = ({ setReady, userAnswers, studentAssociation }) => {
    const localization = useLocalization()
    const [selectedElectoralDistrict, setSelectedElectoralDistrict] = useState(null)

    return (
        <Card className={styles.container}>
            <SelectElectoralDistrict
                value={selectedElectoralDistrict}
                loading={false}
                data={[studentAssociation]}
                selectedStudentAssociationId={studentAssociation._id}
                onChange={(value) => setSelectedElectoralDistrict(value)}
                clearable
            />
            <SuggestionTable userAnswers={userAnswers} studentAssociation={studentAssociation} electoralFilterId={selectedElectoralDistrict} />
            <Button variant="contained" onClick={() => setReady(false)} sx={{ width: { xs: '100%', lg: '33%' } }}>{localization.returnToQuestions}</Button>
        </Card>
    )
}

export default SuggestionList