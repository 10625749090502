import axios from 'axios'

const useCreateAdmin = () => {
    const create = (values) => {
        return axios.post(`${process.env.REACT_APP_API_HOST}/admin`, { ...values }, { withCredentials: true })
            .then(res => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    return create
}

export default useCreateAdmin