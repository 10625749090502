import axios from 'axios'
import { useState } from 'react'

const useUploadCandidateImage = (setShowSnack) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const uploadImage =  (formData) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_HOST}/api/uploadCandidateImage`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(() => {
                setLoading(false)
                setShowSnack('image_success')
            })
            .catch(() => {
                setError(true)
                setLoading(false)
            })
    }

    return [uploadImage, { loading, error }]
}

export default useUploadCandidateImage