import styles from './StudentAssociationImage.module.css'
import { Box } from '@mui/material'
import ImageUpload from '../atoms/ImageUpload'
import useUser from '../../hooks/user/useUser'
import { useEffect, useState } from 'react'
import useUploadImage from '../../hooks/mutations/useUploadImage'

const StudentAssociationImage = ({ value, onChange }) => {
    const user = useUser()
    const [showSupportedFormats, setShowSupportedFormats] = useState(false)
    const [uploadImage, { error, loading, data }] = useUploadImage()


    const onUpload = acceptedFiles => {
        if (acceptedFiles.length === 0){
            setShowSupportedFormats(true)
        }
        const formData = new FormData()
        formData.append('email', user.email)
        formData.append('file', acceptedFiles[0])
        uploadImage(formData)
    }

    useEffect(() => {
        if (data) onChange(data)
    }, [data])
    return(
        <Box className={styles.container}>
            { value && value.length && <img src={value} className={styles.image} /> }
            <ImageUpload loading={loading} error={error} showSupportFormats={showSupportedFormats} callBack={onUpload} setShowSupportedFormats={setShowSupportedFormats}/>
        </Box>
    )
}

export default StudentAssociationImage