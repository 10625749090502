import useLocalization from '../../localization/useLocalization'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import useIsCandidate from '../../hooks/user/useIsCandidate'
import useIsAdmin from '../../hooks/user/useIsAdmin'

const ConditionalNavigationOptions = () => {
    const localization = useLocalization()
    const isCandidate = useIsCandidate()
    const isAdmin = useIsAdmin()
    if (!isCandidate && !isAdmin) return
    return (
        <>
            {
                isCandidate && (
                    <Link to={'/profile'}>
                        <Button color="inherit">{localization.navigationProfile}</Button>
                    </Link>
                )
            }
            {
                isAdmin && (
                    <Link to={'/admin/candidates'}>
                        <Button color="inherit">{localization.adminLogo}</Button>
                    </Link>
                )
            }
        </>
    )
}

export default ConditionalNavigationOptions