import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import styles from './HeadingBox.module.css'

export default function HeadingBox({ headingText, variant }) {

    return (
        <Card className={styles.headingContainer} >
            <Typography variant={variant} component="div" className={styles.headingText}>
                {headingText}
            </Typography>
        </Card>
    )
}