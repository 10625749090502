import NavBar from '../organisms/NavBar'
import { Container } from '@mui/material'
import styles from './default.module.css'

const DefaultLayout = ({ children }) => {

    return (
        <>
            <nav>
                <NavBar />
            </nav>
            <main>
                <Container className={styles.container}>
                    {children}
                </Container>
            </main>
        </>
    )

}

export default DefaultLayout