import { userToken } from '../../constants'
import { isExpired, decodeToken } from 'react-jwt'
import userCheckInterval from '../../utils/userCheckInterval'
import clearUser from '../../utils/clearUser'

const useUser = () => {
    const userT = sessionStorage.getItem(userToken)
    if (!userT) return null
    userCheckInterval.start()
    if (isExpired(userT)) {
        clearUser()
        return null
    }
    return decodeToken(userT)
}

export default useUser