import useLocalization from '../localization/useLocalization'
import { CircularProgress, Container } from '@mui/material'
import HeadingBox from '../components/atoms/HeadingBox'
import DropdownInfoCard from '../components/molecules/DropdownInfoCard'
import useIsCandidate from '../hooks/user/useIsCandidate'
import useGetProfileLazy from '../hooks/queries/useGetProfileLazy'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import useIsLoggedIn from '../hooks/user/useIsLoggedIn'
import useChangeTheme from '../hooks/useChangeTheme'
import useIsAdmin from '../hooks/user/useIsAdmin'

export default function HomePage() {
    const localization = useLocalization()
    const isCandidate = useIsCandidate()
    const isAdmin = useIsAdmin()
    const isLoggedIn = useIsLoggedIn()
    const changeTheme = useChangeTheme()
    const { data, error, loading, fetch } = useGetProfileLazy()

    useEffect(() => {
        if (isCandidate) fetch()
        if (!isLoggedIn || isAdmin) changeTheme()
    }, [])

    const checkProfileValidity = (profile) => {
        if (profile.firstName === '' || profile.lastName === '') return false
        if (profile.studentAssociation === '' || !profile.studentAssociation) return false
        return true
    }

    if (isCandidate && loading) return <CircularProgress size={24} />
    if (isCandidate && error) return <>{error.message}</>
    if (isCandidate && data && !checkProfileValidity(data)) return <Navigate to="/profile" replace={true} state={{ profileMissingRequiredRedirect: true }} />

    return (
        <Container sx={{ marginTop: { lg: '2%', xs: '5%' } }}>
            <HeadingBox headingText={localization.homePageDescription} variant='h5' />
            <DropdownInfoCard profile={data}/>
        </Container>
    )
}
