import * as jose from 'jose'
import { userToken } from '../constants'

const setUserJWT = (object) => {
    const jwt = new jose.UnsecuredJWT(object)
        .setExpirationTime(process.env.NODE_ENV === 'development' ? '5m' : '1h')
        .encode()
    
    sessionStorage.setItem(userToken, jwt)
}

export default setUserJWT