import * as React from 'react'
import { ListItem, ListItemAvatar, Avatar, Typography, ListItemButton, Container } from '@mui/material'
import styles from './SuggestedCandidateItem.module.css'
import CandidateAnswers from './CandidateAnswers'
import useLocalization from '../../localization/useLocalization'

export default function SuggestedCandidateItem({ item, selectedCandidate, handleListItemClick, userAnswers }) {

    const localization = useLocalization()
    const electoralDistrict = item.studentAssociation.electoralDistricts.find(ed => ed._id === item.electoralDistrict)

    if (selectedCandidate !== item._id) return (
        <ListItem key={item._id} alignItems="flex-start">
            <ListItemButton selected={selectedCandidate === item._id} onClick={(event) => handleListItemClick(event, item._id)}>
                <ListItemAvatar>
                    <Avatar alt="../../../public/auto.png" src={item.image} />
                </ListItemAvatar>
                <Container>
                    <Typography >{item.firstName + ' ' + item.lastName}</Typography>
                    <Typography >{electoralDistrict?.name ? electoralDistrict.name : localization.noElectoralDistrictSet}</Typography>
                    <Typography >{item.school}</Typography>
                </Container>
                <Typography sx={{ float: 'right', fontSize: { md: '2rem' } }}>{item.similarity}%</Typography>
            </ListItemButton>
        </ListItem>
    )

    return (
        <ListItem key={item._id} sx={{ width: '100%', flexDirection: 'column', backgroundColor: 'var(--cardBgWhite)', padding: { xs: '0 8px !important' } }}>
            <ListItemButton sx={{ width: '100%' }} onClick={(event) => handleListItemClick(event, null)}>
                <ListItemAvatar>
                    <Avatar alt="../../../public/auto.png" src={item.image} sx={{ height: '75px', width: '75px' }} />
                </ListItemAvatar>
                <Container>
                    <Typography >{item.firstName + ' ' + item.lastName}</Typography>
                    <Typography >{electoralDistrict?.name ? electoralDistrict.name : localization.noElectoralDistrictSet}</Typography>
                    <Typography >{item.school}</Typography>
                </Container>
                <Typography sx={{ float: 'right', fontSize: { md: '2rem' } }}>{item.similarity}%</Typography>
            </ListItemButton>
            <Container sx={{ width: { xs: '100%', lg: '75%' }, marginTop: '1em', marginBottom: '0.5em' }} className={styles.centeredContainer}>
                <Typography align="center" variant="body1" sx={{ fontStyle: 'italic', fontWeight: 'lighter' }}>{item.description}</Typography>
            </Container>
            <Container sx={{ marginTop: '0.5em' }} className={styles.centeredContainer}>
                <CandidateAnswers candidateAnswers={item.answeredQuestions} userAnswers={userAnswers}/>
            </Container>
        </ListItem>
    )
}