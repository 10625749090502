import './App.css'
import DefaultLayout from './components/layouts/default'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './routes/Home'
import LoginPage from './routes/Login'
import QuestionsPage from './routes/Questions'
import ProfilePage from './routes/Profile'
import RequireCandidate from './routes/utilities/CandidateRoute'
import AdminPage from './routes/Admin'
import RequireAdmin from './routes/utilities/AdminRoute'
import RegistrationPage from './routes/Registration'
import ResetPage from './routes/Reset'
import UserControls from './components/organisms/UserControls'
import StudentAssociationControls from './components/organisms/StudentAssociationControls'
import QuestionControls from './components/organisms/QuestionControls'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { useState } from 'react'
import ThemeContext from './contexts/ThemeContext'
import AdminControls from './components/organisms/AdminControls'

export default function App() {
    const [theme, setTheme] = useState(createTheme())

    const changeTheme = (t) => {
        if (t) return setTheme(createTheme(t))
        setTheme(createTheme())
    }

    return (
        <BrowserRouter>
            <ThemeContext.Provider value={{ theme, changeTheme }}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <DefaultLayout>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path='/questions' element={<QuestionsPage />} />
                            <Route path="/profile" element={<RequireCandidate element={<ProfilePage />}/>}  />
                            <Route path="/admin" element={<RequireAdmin element={<AdminPage />}/>}>
                                <Route path="candidates" element={<UserControls />} />
                                <Route path="studentassociations" element={<StudentAssociationControls />} />
                                <Route path="questions" element={<QuestionControls />} />
                                <Route path="admins" element={<AdminControls />} />
                            </Route>
                            <Route path="/register/:email/:id" element={<RegistrationPage />} />
                            <Route path="/reset/:email/:id" element={<ResetPage />} />
                        </Routes>
                    </DefaultLayout>
                </ThemeProvider>
            </ThemeContext.Provider>
        </BrowserRouter>

    )
}

