import axios from 'axios'

const useUpdateProfile = (refetchProfileFunction) => {

    return (values) => {
        return axios.post(`${process.env.REACT_APP_API_HOST}/updateProfile`, { data: values }, { withCredentials: true })
            .then(res => {
                if (res.status !== 200) return 'error'
                refetchProfileFunction()
                return 'success'
            })
            .catch(() => {
                return 'error'
            })
    }
}

export default useUpdateProfile