import { useState } from 'react'
import styles from './Registration.module.css'
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Card, CardContent, Typography, Stack, FormControl, TextField, Snackbar, Alert, Button } from '@mui/material'
import useLocalization from '../localization/useLocalization'
import useRegistration from '../hooks/mutations/useRegistration'
import { alertLength } from '../constants'

export default function RegistrationPage() {
    const localization = useLocalization()
    const params = useParams()
    const navigate = useNavigate()
    const [showSnack, setShowSnack] = useState('')
    const [formValues, setFormValues] = useState({
        id: params.id,
        email: params.email,
        password: '',
        passwordConfirmation: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        useRegistration(formValues).then((returnValue) => {
            if(returnValue == 'error') return setShowSnack('error')
            navigate('/login', { state: { reseted: true, email: formValues.email } })
        }).catch(() => {
            setShowSnack('error')
        })
    }

    const validateSubmit = () => {
        if (formValues.password !== formValues.passwordConfirmation || formValues.password === '') return true
        return false
    }

    return (
        <Grid className={styles.customContainer}>
            <Card className={styles.card}>
                <Snackbar autoHideDuration={alertLength} open={showSnack === 'error'} onClose={() => setShowSnack('')}>
                    <Alert severity="error">{localization.profileUpdateError}</Alert>
                </Snackbar>
                <CardContent style={{ width: '80%' }}>
                    <Typography variant="h6">{localization.registerButton}</Typography>
                    <form onSubmit={handleSubmit}>
                        <Stack direction="column" style={{ padding: '1em 0' }} spacing={2}>
                            <FormControl variant="standard">
                                <TextField required name="password" type="password" value={formValues.password} label={localization.passwordPlaceHolder} fullWidth onChange={handleChange} />
                            </FormControl>
                            <FormControl variant="standard" style={{ marginBottom: '1em' }}>
                                <TextField required name="passwordConfirmation" type="password" value={formValues.passwordConfirmation} error={formValues.password !== formValues.passwordConfirmation} label={localization.passwordConfirmationPlaceHolder} fullWidth onChange={handleChange} />
                            </FormControl>
                            <Button variant="contained" color="primary" type="submit" disabled={validateSubmit()}>
                                { localization.registerButton }
                            </Button>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}
