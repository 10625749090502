import { useEffect, useState } from 'react'
import axios from 'axios'

const useGetCandidates = (filter) => {
    const [data, setData] = useState()
    const [error, setError] = useState()

    const fetchCandidates = () => {
        axios.post(`${process.env.REACT_APP_API_HOST}/getFilteredCandidates`, { filter : filter }, { withCredentials: true })
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                setError(err)
            })
    }

    useEffect(() => {
        fetchCandidates()
    }, [])

    return { data, error, loading: !error && !data, refetch: fetchCandidates }
}

export default useGetCandidates