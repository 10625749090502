import { useEffect, useState } from 'react'
import styles from './AddOneStudentAssociation.module.css'
import useLocalization from '../../localization/useLocalization'
import { Card, Alert, FormControl, Snackbar, Stack, TextField, FormLabel, Button } from '@mui/material'
import { alertLength } from '../../constants'
import SubHeader from '../atoms/SubHeader'
import ElectoralDistrictPicker from './ElectoralDistrictPicker'
import useAddOneStudentAssociation from '../../hooks/mutations/useAddOneStudentAssociation'
import useEditStudentAssociation from '../../hooks/mutations/useEditStudentAssociation'
import ColorPicker from '../atoms/ColorPicker'
import StudentAssociationImage from './StudentAssociationImage'

const AddOneStudentAssociation = ({ refetch, edited, setEdited }) => {
    const localization = useLocalization()

    const [showSnack, setShowSnack] = useState('')
    const formInitialState = {
        _id: '',
        name: '',
        electoralDistricts: [],
        themeColor: '',
        themeLogo: '',
    }
    const [formValues, setFormValues] = useState(formInitialState)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleColorChange = (value) => {
        if (value === '#NaNNaNNaN') return
        setFormValues({
            ...formValues,
            themeColor: value
        })
    }

    const handleListChange = (val) => {
        setFormValues({
            ...formValues,
            electoralDistricts: val
        })
    }

    const handleImageChange = (val) => {
        setFormValues({
            ...formValues,
            themeLogo: val
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const values = formValues
        values.electoralDistricts = values.electoralDistricts.map((district) => Number.isInteger(district._id) ? { name: district.name } : { _id: district._id, name: district.name })
        if (values._id === '') delete(values._id)

        if (edited) {
            setEdited(null)
            setFormValues(formInitialState)
            useEditStudentAssociation(values)
                .then(res => {
                    refetch()
                    setShowSnack(res)
                })

        } else {
            setFormValues(formInitialState)
            useAddOneStudentAssociation(values)
                .then(res => {
                    refetch()
                    setShowSnack(res)
                })
        }
    }

    const clearForm = () => {
        setEdited(null)
        setFormValues(formInitialState)
    }

    useEffect(() => {
        if(edited) {
            setFormValues({
                name: edited.name || '',
                electoralDistricts: edited.electoralDistricts || [],
                _id: edited._id || '',
                themeColor: edited.themeColor || '',
                themeLogo: edited.themeLogo || ''
            })
        }
    }, [edited])

    return (
        <Card className={styles.mainContainer}>
            <SubHeader headingText={!edited ? localization.createStudentAssociation : localization.editStudentAssociation} variant={'h6'} />
            <form onSubmit={handleSubmit} autoComplete="off" className={styles.secondaryContainer}>
                <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => setShowSnack('')}>
                    <Alert severity="success">{localization.studentAssociationSuccess}</Alert>
                </Snackbar>
                <Snackbar autoHideDuration={alertLength} open={showSnack === 'error'} onClose={() => setShowSnack('')}>
                    <Alert severity="error">{localization.studentAssociationError}</Alert>
                </Snackbar>
                <Stack direction="column" spacing={2} sx={{ marginTop: '1em' }}>
                    <FormControl sx={{ display: 'none' }}>
                        <TextField name="name" value={formValues._id} />
                    </FormControl>
                    <FormControl>
                        <TextField id="name" name="name" value={formValues.name} label={localization.studentAssociationHolder} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <ColorPicker id="studentAssociationColor" value={formValues.themeColor} onChange={handleColorChange}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="studentAssociationLogo" sx={{ fontWeight: '700' }}>{localization.studentAssociationLogoLabel}</FormLabel>
                        <StudentAssociationImage id="studentAssociationLogo" value={formValues.themeLogo} onChange={handleImageChange} />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="electoralDistricts" sx={{ fontWeight: '700' }}>{localization.electoralDistrict}</FormLabel>
                        <ElectoralDistrictPicker id="electoralDistricts" name="electoralDistricts" value={formValues.electoralDistricts} onChange={handleListChange}/>
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="primary" type="submit" sx={{ width: '100%'  }}>
                            { localization.fillFormButton }
                        </Button>
                        <Button variant="outlined" type="button" onClick={() => clearForm()} sx={{ width: '100%'  }}>{edited ? localization.cancelButton : localization.clearButton}</Button>
                    </Stack>
                </Stack>
            </form>
        </Card>
    )
}

export default AddOneStudentAssociation