import { useContext } from 'react'
import themeContext from '../contexts/ThemeContext'
import { createTheme } from '@mui/material'
import useIsAdmin from './user/useIsAdmin'

const buildTheme = (studentAssociation, isAdmin = false) => {
    let theme = {
        palette: {
            background: {
                default: studentAssociation?.themeColor || (isAdmin ? '#ffffff' : '#1976d2')
            }
        }
    }

    if (studentAssociation?.themeColor?.length > 0 && /^#([0-9A-F]{3}){1,2}$/i.test(studentAssociation?.themeColor)) theme = {
        ...theme,
        palette: {
            ...theme.palette,
            primary: {
                main: studentAssociation.themeColor
            }
        }
    }

    if (studentAssociation?.themeLogo?.length > 0) theme = {
        ...theme,
        image: studentAssociation.themeLogo
    }
    return createTheme(theme)
}

const useChangeTheme = () => {
    const { changeTheme } = useContext(themeContext)
    const isAdmin = useIsAdmin()
    return (studentAssociation) => {
        const theme = buildTheme(studentAssociation, isAdmin)
        changeTheme(theme)
    }
}

export default useChangeTheme