import { userToken } from '../constants'
import { isExpired } from 'react-jwt'
import clearUser from './clearUser'
let interval = null

const check = () => {
    const userT = sessionStorage.getItem(userToken)
    if(isExpired(userT)) clearUser()
}

const userInterval = {
    start: () => {
        const userT = sessionStorage.getItem(userToken)
        if (!userT && interval) {
            clearInterval(interval)
            return null
        }
        if (!userT) return null
        if (!interval) {
            interval = setInterval(check, 2000)
        }
    }
}
Object.freeze(userInterval)
export default userInterval