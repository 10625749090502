import { Navigate, useLocation } from 'react-router-dom'
import useIsCandidate from '../../hooks/user/useIsCandidate'

const RequireCandidate = ({ element }) => {
    const isCandidate = useIsCandidate()
    const location = useLocation()
    if (!isCandidate) return <Navigate to="/login" state={{ from: location }} replace />

    return element
}

export default RequireCandidate

