import axios from 'axios'
import { useState } from 'react'
import useLocalization from '../../localization/useLocalization'
import { alertLength } from '../../constants'
import setUserJWT from '../../utils/setUserJWT'

const useLogin = () => {
    const localization = useLocalization()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const login = (body) => {
        setLoading(true)
        return axios.post(`${process.env.REACT_APP_API_HOST}/login`, body, { withCredentials: true })
            .then((result) => {
                if (result.data?.tokenUser) {
                    setUserJWT(result.data.tokenUser)
                }
                setLoading(false)
            })
            .catch(() => {
                setError(localization.wrongPasswordHolder)
                setTimeout(() => {
                    setError(false)
                }, alertLength)
                setLoading(false)
                throw new Error()
            })
    }
    return { login, loading, error }
}

export default useLogin