import styles from './ProfileImage.module.css'
import { Box } from '@mui/material'
import ImageUpload from '../atoms/ImageUpload'
import useUploadCandidateImage from '../../hooks/mutations/useUploadCandidateImage'
import useUser from '../../hooks/user/useUser'
import { useState } from 'react'

const ProfileImage = ({ source, setShowSnack, name }) => {
    const user = useUser()
    const [uploadImage, { loading, error }] = useUploadCandidateImage(setShowSnack)
    const [showSupportedFormats, setShowSupportedFormats] = useState(false)


    const onUpload = acceptedFiles => {
        if (acceptedFiles.length === 0){
            setShowSupportedFormats(true)
        }
        const formData = new FormData()
        formData.append('email', user.email)
        formData.append('file', acceptedFiles[0])
        uploadImage(formData)
    }
    return(
        <Box className={styles.container}>
            <img src={source} className={styles.image} />
            <ImageUpload name={name} loading={loading} error={error} showSupportFormats={showSupportedFormats} callBack={onUpload} setShowSupportedFormats={setShowSupportedFormats}/>
        </Box>
    )
}

export default ProfileImage