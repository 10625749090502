import { useState } from 'react'
import styles from './AddManyCandidates.module.css'
import useLocalization from '../../localization/useLocalization'
import CSVReader from 'react-csv-reader'
import { Card, Stack, Box, Alert, Container, Tooltip, Divider } from '@mui/material'
import useAddManyCandidates from '../../hooks/mutations/useAddManyCandidates'
import SubHeader from '../atoms/SubHeader'

const AddManyCandidates = ({ refetch }) => {
    const localization = useLocalization()
    const [linkArr, setLinkArr] = useState([])

    const parseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header =>
            header
                .toLowerCase()
                .replace(/\W/g, '_')
    }

    const handleOnFileLoad = (data) => {  
        useAddManyCandidates(data).then(returnValue => {
            setLinkArr(returnValue.data)

            document.querySelector('#result2').scrollIntoView({
                behavior: 'smooth'
            })
            
        })
        refetch()
        document.getElementById('input').value = ''    
    }

    const copyLink = (e) => {
        let text = e.event.target.innerHTML
        if (text) {
            text = text.split('}')[1].trim()
            navigator.clipboard.writeText(text)
        }
    }

    return (
        <Card className={styles.mainContainer} >
            <SubHeader headingText={localization.addCandidates} variant={'h6'} />
            <Stack direction="row" className={styles.secondaryContainer}>
                <CSVReader
                    cssClass={styles.reader}
                    cssInputClass={styles.input}
                    cssLabelClass={styles.label}
                    label={localization.selectFileToUpload}
                    onFileLoaded={handleOnFileLoad}
                    onError={null}
                    parserOptions={parseOptions}
                    inputId="input"
                    inputName={'input'}
                    accept=".csv, text/csv, text/plain"
                    strict={true}
                />
                <Box className={styles.alertBox}>
                    { <Alert severity="info" onClose={null}>{localization.manyCandidates}</Alert> }
                </Box>
            </Stack>
            <Container id="result2" className={styles.resultContainer}>
                <SubHeader headingText={localization.results} variant={'h6'} />
                <Divider variant="middle" sx={{ marginTop: '10px' }} />
                {
                    linkArr?.map((item, index) => {
                        return (
                            item.status ? 
                                <Tooltip key={index} title={localization.copyLink} placement="top"> 
                                    <Alert severity="success" className={styles.resultItem} onClick={(event) => copyLink({ event })}>{item.email} {item.val}</Alert>
                                </Tooltip>
                                :
                                <Alert key={index} severity="error" className={styles.resultItemErr}>{item.val}</Alert>
                        )
                    })
                }
            </Container>
        </Card>
    )
}

export default AddManyCandidates