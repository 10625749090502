import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useLocalization from '../localization/useLocalization'
import styles from './Questions.module.css'
import { Container, CircularProgress } from '@mui/material'
import useGetFilteredQuestions from '../hooks/queries/useGetFilteredQuestions'
import HeadingBox from '../components/atoms/HeadingBox'
import useIsLoggedIn from '../hooks/user/useIsLoggedIn'
import useChangeTheme from '../hooks/useChangeTheme'
import useGetProfileLazy from '../hooks/queries/useGetProfileLazy'
import QuestionItemList from '../components/molecules/QuestionItemList'
import SuggestionList from '../components/molecules/SuggestionList'

export default function QuestionsPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const { data, error, loading } = useGetFilteredQuestions(location.state?.studentAssociation)
    const { fetch } = useGetProfileLazy()
    const [ready, setReady] = useState(false)
    const [userAnswers, setUserAnswers] = useState([])
    const localization = useLocalization()
    const changeTheme = useChangeTheme()
    const isLoggedIn = useIsLoggedIn()

    const populateAnswerState = (data) => {
        const setAnswers = (d, answeredQuestions = []) => {
            const answers = d.map((item) => {
                const previousAnswer = answeredQuestions.find(q => q.question === item._id)
                return { id: item._id, sequence: item.sequence, value: previousAnswer?.value || 0, description: previousAnswer?.description || '' }
            })
            setUserAnswers(answers)
        }

        if(data) {
            if (!isLoggedIn) return setAnswers(data)
            fetch().then((fetchedProfile) => {
                setAnswers(data, fetchedProfile.answeredQuestions)
            })
        }
    }

    const compareSequence = (a, b) => a.sequence - b.sequence

    const handleChange = (id, value, desc) => {
        setUserAnswers(prevState => {
            return prevState.map(obj => {
                if (obj.id === id) return { ...obj, value: value, description: desc }
                return obj
            })
        })
    }

    useEffect(() => {
        if(userAnswers.length === 0 && data && data.length > 0) {
            populateAnswerState(data.sort(compareSequence))
        }
    }, [userAnswers, data])

    useEffect(() => {
        if (!location.state?.studentAssociation) navigate('/')
        changeTheme(location.state.studentAssociation)
    }, [])

    if(loading || (userAnswers?.length === 0 && data?.length !== 0)) return <CircularProgress size={20} />
    if(error || !data) return <div>{error}</div>

    return (
        <Container className={styles.questionsContainer}>
            <HeadingBox headingText={localization.answerAndSend} variant={'h5'} />
            { !ready && <QuestionItemList data={data} handleChange={handleChange} userAnswers={userAnswers} setReady={setReady}/> }
            { ready && <SuggestionList userAnswers={userAnswers} setReady={setReady} studentAssociation={location.state.studentAssociation}/> }
        </Container>
    )
}