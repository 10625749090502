import { Container } from '@mui/material'
import AdminsTable from '../molecules/AdminsTable'
import useGetAdmins from '../../hooks/queries/useGetAdmins'
import AddOneAdmin from '../molecules/AddOneAdmin'


const AdminControls = () => {
    const { data, loading, error, refetch }= useGetAdmins()

    const setEdited = () => {}
    return(
        <Container sx={{ width: '100%', marginTop:'5%', marginBottom:'5%' }} >
            <AdminsTable data={data} error={error} loading={loading} refetch={refetch} setEdited={setEdited} />
            <AddOneAdmin refetch={refetch} />
        </Container>
    )
}

export default AdminControls