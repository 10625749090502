import useLocalization from '../../localization/useLocalization'
import { useEffect, useState } from 'react'
import { Alert, Button, Card, CircularProgress, Container, Snackbar, Stack, Typography } from '@mui/material'
import AxiosError from './AxiosError'
import styles from './StudentAssociationTable.module.css'
import { alertLength } from '../../constants'
import SubHeader from '../atoms/SubHeader'
import { DataGrid } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import useDeleteAdmins from '../../hooks/mutations/useDeleteAdmins'

const AdminsTable = ({ data, loading, error, refetch }) => {
    const deleteAdmins = useDeleteAdmins()
    const localization = useLocalization()
    const [rows, setRows] = useState([])
    const [selectionModel, setSelectionModel] = useState([])
    const [showSnack, setShowSnack] = useState('')
    const [failed, setFailed] = useState('')
    const [deleteError, setDeleteError] = useState('')

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'email', headerName: localization.emailPlaceHolder, flex: 3 },
    ]

    const handleDelete = () => {
        if(selectionModel.length === 0) return
        deleteAdmins(selectionModel)
            .then(() => {
                setShowSnack('success')
            })
            .catch(({ response: { data: errorData } }) => {
                setShowSnack('error')
                if (!errorData) return setDeleteError('Unexpected error happened')
                if (errorData.deleted && errorData.total) return setFailed(`${errorData.deleted}/${errorData.total}`)
                setDeleteError(errorData)
            })
            .finally(() => {
                refetch()
            })
    }

    useEffect(() => {
        if (!data) return

        let arr = []
        data.map(item => {
            const newItem = {
                id: item._id,
                email: item.email,
            }
            arr.push(newItem)
        })
        setRows(arr)
    }, [data])

    const clearSnacks = () => {
        setShowSnack('')
        setFailed('')
        setDeleteError('')
    }

    if (loading) return <CircularProgress size={28} sx={{ marginTop: '1em' }} />
    if (error) return <AxiosError message={error.response.data} statuscode={error.response.status} />

    return (
        <Card className={styles.mainContainer} >
            <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => clearSnacks()}>
                <Alert severity="success">{localization.itemDeleteSuccess}</Alert>
            </Snackbar>
            <Snackbar autoHideDuration={alertLength * 2} open={showSnack === 'error' && failed.length > 0} onClose={() => clearSnacks()}>
                <Alert severity="error">{failed + localization.itemDeleteError}</Alert>
            </Snackbar>
            <Snackbar autoHideDuration={alertLength * 2} open={showSnack === 'error' && deleteError.length > 0} onClose={() => clearSnacks()}>
                <Alert severity="error">{deleteError}</Alert>
            </Snackbar>
            <SubHeader headingText={localization.admins} variant={'h6'} />
            <Container className={styles.secondaryContainer}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel)
                    }}
                    selectionModel={selectionModel}
                />
            </Container>
            <Container className={styles.deleteBtn}>
                <Button variant='outlined' color='error' type='button' onClick={() => handleDelete()}><Stack direction="row" ><DeleteIcon /><Typography sx={{ paddingTop: '2px' }}>{localization.deleteSelected}</Typography></Stack></Button>
            </Container>
        </Card>
    )
}

export default AdminsTable