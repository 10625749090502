import { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import useLocalization from '../../localization/useLocalization'
import { Alert, Stack, TextField } from '@mui/material'
import useLogin from '../../hooks/mutations/useLogin'
import LoadingButton from '../atoms/LoadingButton'
import { useNavigate } from 'react-router-dom'
import useIsCandidate from '../../hooks/user/useIsCandidate'
import useIsAdmin from '../../hooks/user/useIsAdmin'

const LoginForm = ({ from, email = '' }) => {
    const [formValues, setFormValues] = useState({
        email: email,
        password: '',
    })
    const { login, loading, error } = useLogin(from)
    const localization = useLocalization()
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        login(formValues)
            .then(() => {
                if(from) return navigate(from)
                const isCandidate = useIsCandidate()
                const isAdmin = useIsAdmin()
                if (isAdmin) return navigate('/admin/candidates')
                if (isCandidate) return navigate('/profile')
                navigate('/')
            })
            .catch(() => {
                setFormValues({
                    ...formValues,
                    password: '',
                })
            })
    }

    return (
        <form onSubmit={handleSubmit}>
            { error && <Alert sx={{ marginTop: '1em' }} severity="error">{error}</Alert>}
            <Stack direction="column" style={{ padding: '1em 0' }} spacing={2}>
                <FormControl variant="standard" >
                    <TextField name="email" type="email" value={formValues.email} label={localization.emailPlaceHolder} fullWidth onChange={handleChange} required/>
                </FormControl>
                <FormControl variant="standard" style={{ marginBottom: '1em' }} >
                    <TextField name="password" type="password" value={formValues.password} label={localization.passwordPlaceHolder} fullWidth onChange={handleChange} required/>
                </FormControl>
                <LoadingButton loading={loading} variant="contained" color="primary" type="submit">
                    { localization.loginButton }
                </LoadingButton>
            </Stack>
        </form>

    )
}

export default LoginForm