import { useState } from 'react'
import { Container } from '@mui/material'
import useGetStudentAssociations from '../../hooks/queries/useGetStudentAssociations'
import StudentAssociationTable from '../molecules/StudentAssociationTable'
import AddOneStudentAssociation from '../molecules/AddOneStudentAssociation'

const StudentAssociationControls = () => {
    const { data, error, loading, refetch } = useGetStudentAssociations()
    const [edited, setEdited] = useState(null)

    return (
        <Container sx={{ width: '100%', marginTop:'5%', marginBottom:'5%' }} >
            <StudentAssociationTable data={data} error={error} loading={loading} refetch={refetch} setEdited={setEdited} />
            <AddOneStudentAssociation refetch={refetch} edited={edited} setEdited={setEdited} />
        </Container>
    )
}

export default StudentAssociationControls