import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useLocalization from '../../localization/useLocalization'
import { useNavigate } from 'react-router-dom'
import styles from './DropdownInfoCard.module.css'
import SelectStudentAssociation from './SelectStudentAssociation'
import useGetStudentAssociations from '../../hooks/queries/useGetStudentAssociations'
import { useEffect } from 'react'
import useChangeTheme from '../../hooks/useChangeTheme'

export default function DropdownInfoCard({ profile }) {
    const localization = useLocalization()
    const { data: studentAssociationData, error: studentAssociationError, loading: studentAssociationLoading } = useGetStudentAssociations()
    const navigate = useNavigate()
    const changeTheme = useChangeTheme()

    useEffect(() => {
        if (profile?.studentAssociation && studentAssociationData) {
            const studentAssociation = studentAssociationData.find(sa => sa._id === profile.studentAssociation._id)
            navigate('/questions', { state: { studentAssociation, profile } })
        }
    }, [profile, studentAssociationData])

    const handleChange = (_id, studentAssociation) => {
        changeTheme(studentAssociation)
        navigate('/questions', { state: { studentAssociation } })
    }

    return (
        <Card className={styles.dropdownContainer} sx={{ width: { xs: '90%' } }}>
            <CardContent className={styles.dropdownCardInfo}>
                <Typography>{localization.homePageInfo}</Typography>
            </CardContent>
            <CardActions>
                <SelectStudentAssociation data={studentAssociationData} loading={studentAssociationLoading} error={studentAssociationError} onChange={handleChange} value={''}/>
            </CardActions>
        </Card>
    )
}
