import * as React from 'react'
import List from '@mui/material/List'
import { CircularProgress } from '@mui/material'
import useGetSuggestions from '../../hooks/queries/useGetSuggestions'
import SuggestedCandidateItem from '../molecules/SuggestedCandidateItem'

export default function SuggestionTable({ userAnswers, studentAssociation, electoralFilterId }) {
    const { data, error, loading } = useGetSuggestions(studentAssociation, userAnswers)
    const [selectedCandidate, setSelectedCandidate] = React.useState()

    const handleListItemClick = (event, id) => {
        setSelectedCandidate(id)
    }
    
    if(loading) return <CircularProgress size={20} />
    if(error) return <div>{error}</div>
    const filteredData = electoralFilterId ? data.filter(d => d.electoralDistrict === electoralFilterId) : data
    const sortedAndFilteredData = filteredData.sort((a, b) => b.similarity - a.similarity)
    
    return (
        <List sx={{ width: '100%', bgcolor: 'var(--cardBgWhite)', margin: '1em auto' }}>
            {
                sortedAndFilteredData.map((item) => {
                    return <SuggestedCandidateItem key={item._id} item={item} handleListItemClick={handleListItemClick} selectedCandidate={selectedCandidate} userAnswers={userAnswers} />
                })
            }
        </List>
    )
}