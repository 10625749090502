import { useState } from 'react'
import Typography from '@mui/material/Typography'

const TruncateTypography = ({ variant, align, sx, children, showLength }) => {
    const [showFullDescription, setShowFullDescription] = useState(false)

    const truncateText = (text) => {
        const shownText = text.slice(0, showLength)
        return `${shownText}...`
    }

    return (
        <Typography variant={variant} align={align} sx={sx} onClick={() => setShowFullDescription(!showFullDescription)}>
            {(!showFullDescription && children.length > showLength) ? truncateText(children) : children }
        </Typography>
    )
}

export default TruncateTypography