import { useState } from 'react'
import { Card, CardContent, Grid, Typography, Snackbar, Alert } from '@mui/material'
import useLocalization from '../localization/useLocalization'
import LoginForm from '../components/organisms/LoginForm'
import useIsLoggedIn from '../hooks/user/useIsLoggedIn'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import styles from './Login.module.css'
import { alertLength } from '../constants'

const LoginPage = () => {
    const localization = useLocalization()
    const navigate = useNavigate()
    const loggedIn = useIsLoggedIn()
    const location = useLocation()
    const [showSnack, setShowSnack] = useState('')

    useEffect(() => {
        if (location.state?.reseted) setShowSnack('success')
        if (loggedIn) navigate('/', { replace: true })
    }, [])

    return (
        <Grid className={styles.customContainer}>
            <Card className={styles.card}>
                <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => setShowSnack('')}>
                    <Alert severity="success">{localization.profileUpdateSuccess}</Alert>
                </Snackbar>
                <CardContent style={{ width: '80%' }}>
                    <Typography variant="h6">{localization.login}</Typography>
                    <LoginForm from={location.state?.from ? location.state?.from : null } email={location?.state?.email || ''}/>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LoginPage