import { Alert, CircularProgress, FormControl, FormLabel, Snackbar, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import LoadingButton from '../atoms/LoadingButton'
import useLocalization from '../../localization/useLocalization'
import SelectStudentAssociation from '../molecules/SelectStudentAssociation'
import SelectElectoralDistrict from '../molecules/SelectElectoralDistrict'
import useGetProfile from '../../hooks/queries/useGetProfile'
import useUpdateProfile from '../../hooks/mutations/useUpdateProfile'
import { alertLength } from '../../constants'
import ProfileImage from '../molecules/ProfileImage'
import AxiosError from '../molecules/AxiosError'
import useGetStudentAssociations from '../../hooks/queries/useGetStudentAssociations'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

const ProfileForm = ({ setShowWarning }) => {
    const localization = useLocalization()
    const { data, error, loading, refetch } = useGetProfile()
    const { data: studentAssociationData, error: studentAssociationError, loading: studentAssociationLoading } = useGetStudentAssociations()

    const updateProfile = useUpdateProfile(refetch)
    const [showSnack, setShowSnack] = useState('')
    const formInitialValues = {
        email: '',
        firstName: '',
        lastName: '',
        school: '',
        electoralDistrict: '',
        studentAssociation: '',
        campus: '',
        profilePicture: '',
        description: ''
    }
    const [formValues, setFormValues] = useState(formInitialValues)
    
    useEffect(() => {
        if (!data) return
        setFormValues({
            email: data.email || formInitialValues.email,
            firstName: data.firstName || formInitialValues.firstName,
            lastName: data.lastName || formInitialValues.lastName,
            school: data.school || formInitialValues.school,
            electoralDistrict: data.electoralDistrict || formInitialValues.electoralDistrict,
            studentAssociation: data.studentAssociation?._id || formInitialValues.studentAssociation,
            campus: data.campus || formInitialValues.campus,
            profilePicture: data.profilePicture || formInitialValues.profilePicture,
            description: data.description || formInitialValues.description
        })
    }, [data])

    useEffect(() => {
        if ((formValues.firstName === '' || formValues.lastName === '' || formValues.studentAssociation === '') && data) return setShowWarning(true)
        setShowWarning(false)
    }, [formValues])


    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        updateProfile(formValues)
            .then(snackValue => {
                setShowSnack(snackValue)
            })
    }

    if (loading) return <CircularProgress size={28} sx={{ marginTop: '1em' }} />
    if (error) return <AxiosError message={error.response?.data} statuscode={error.response?.status} />

    return (
        <form onSubmit={handleSubmit} autoComplete="off" style={{ width:'100%' }}>
            <Snackbar autoHideDuration={alertLength} open={showSnack === 'image_success'} onClose={() => setShowSnack('')}>
                <Alert severity="success">{localization.profileImageUploaded}</Alert>
            </Snackbar>
            <Snackbar autoHideDuration={alertLength} open={showSnack === 'success'} onClose={() => setShowSnack('')}>
                <Alert severity="success">{localization.profileUpdateSuccess}</Alert>
            </Snackbar>
            <Snackbar autoHideDuration={alertLength} open={showSnack === 'error'} onClose={() => setShowSnack('')}>
                <Alert severity="error">{localization.profileUpdateError}</Alert>
            </Snackbar>
            <Stack direction="column" spacing={2} sx={{ marginTop: '1em' }}>
                <FormControl sx={{ display: 'none' }}>
                    <TextField name="email" value={formValues.email} />
                </FormControl>
                <FormControl >
                    <TextField name="firstName" value={formValues.firstName} label={localization.firstName} fullWidth onChange={handleChange} required error={formValues.firstName === '' } />
                </FormControl>
                <FormControl >
                    <TextField name="lastName" value={formValues.lastName} label={localization.lastName} fullWidth onChange={handleChange} required error={formValues.lastName === '' } />
                </FormControl>

                <SelectStudentAssociation fieldError={formValues.studentAssociation === '' } required name="studentAssociation" data={studentAssociationData} loading={studentAssociationLoading} error={studentAssociationError} value={formValues.studentAssociation} onChange={ (_id, _sa, e) => handleChange(e) }/>
                <SelectElectoralDistrict name="electoralDistrict" data={studentAssociationData} loading={studentAssociationLoading} error={studentAssociationError} selectedStudentAssociationId={formValues.studentAssociation} value={formValues.electoralDistrict} label={localization.electoralDistrict} onChange={ (_id, _sa, e) => handleChange(e) } />

                <FormControl>
                    <TextField name="school" value={formValues.school} label={localization.school} fullWidth onChange={handleChange} />
                </FormControl>
                <FormControl>
                    <TextField name="campus" value={formValues.campus} label={localization.campus} fullWidth onChange={handleChange} />
                </FormControl>
                <FormControl>
                    <TextField name="description" multiline value={formValues.description} label={localization.description} fullWidth onChange={handleChange} />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="profilePicture" sx={{ fontWeight: '700' }}>{localization.profilePictureLabel}</FormLabel>
                    <ProfileImage name="profilePicture" source={data.image} setShowSnack={setShowSnack}/>
                </FormControl>
                <Stack spacing={2}>
                    <LoadingButton loading={loading} variant="contained" color="primary" type="submit">
                        { localization.profileButton }
                    </LoadingButton>
                    <Link to="/">
                        <Button variant="outlined" color="primary" fullWidth>
                            {localization.answerQuestions}
                        </Button>
                    </Link>
                </Stack>

            </Stack>
        </form>
    )
}

export default ProfileForm