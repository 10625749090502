import { useState } from 'react'
import useGetCandidates from '../../hooks/queries/useGetCandidates'
import { Container } from '@mui/material'
import CandidateTable from '../molecules/CandidateTable'
import AddOneCandidate from '../molecules/AddOneCandidate'
import AddManyCandidates from '../molecules/AddManyCandidates'
import ResetCandidatePassword from '../molecules/ResetCandidatePassword'

const UserControls = () => {
    const { data, error, loading, refetch } = useGetCandidates()
    const [edited, setEdited] = useState(null)

    return (
        <Container sx={{ width: '100%', marginTop:'5%', marginBottom:'5%' }} >
            <CandidateTable data={data} error={error} loading={loading} refetch={refetch} setEdited={setEdited} />
            <AddOneCandidate refetch={refetch} edited={edited} setEdited={setEdited} />
            <AddManyCandidates refetch={refetch} />
            <ResetCandidatePassword />
        </Container>
    )
}

export default UserControls