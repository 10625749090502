import axios from 'axios'
import { useState } from 'react'

const useUploadCandidateImage = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState()
    const uploadImage =  (formData) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_HOST}/api/uploadImage`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then((res) => {
                setData(res.data)
                setLoading(false)
            })
            .catch(() => {
                setError(true)
                setLoading(false)
            })
    }

    return [uploadImage, { loading, error, data }]
}

export default useUploadCandidateImage