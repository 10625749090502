import { useDropzone } from 'react-dropzone'
import { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './ImageUpload.module.css'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { CircularProgress, Typography, Snackbar, Alert } from '@mui/material'
import { alertLength } from '../../constants'
import useLocalization from '../../localization/useLocalization'

function ImageUpload({ loading, error, callBack, showSupportedFormats, setShowSupportedFormats }) {
    const [showSnack, setShowSnack] = useState(false)
    const localization = useLocalization()
    const onDrop = useCallback(acceptedFiles => callBack(acceptedFiles), [])

    useEffect(() => {
        if (error) setShowSnack(error)
    }, [error])

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop, accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'],  'image/gif': ['.gif'] } })

    // TODO: Drag and drop zone css
    return (
        <div {...getRootProps({ className: classNames('dropzone', styles.container) })} >
            <Snackbar autoHideDuration={alertLength} open={showSnack} onClose={() => setShowSnack(false)}>
                <Alert severity="error">{localization.imageUploadFailed}</Alert>
            </Snackbar>
            <Snackbar autoHideDuration={alertLength} open={showSupportedFormats} onClose={() => setShowSupportedFormats(false)}>
                <Alert severity="error">{localization.uploadErrorSupportedFormats}</Alert>
            </Snackbar>
            <input {...getInputProps()} />
            <div className={isDragActive ? classNames('text-center', isDragReject ? styles.reject : styles.accept, styles.dragAndDropZone) :classNames('text-center', styles.dragAndDropZone)}>
                <div className={classNames('dropzone-content', styles.dropZoneContent)}>
                    { loading ? <CircularProgress size={24} />
                        :(
                            <>
                                <FileUploadIcon className={styles.uploadIcon}/>
                                <Typography sx={{ textAlign: { xs: 'center' } }}>{localization.fileUploadPrompt}</Typography>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default ImageUpload