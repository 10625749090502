import axios from 'axios'

const useEditStudentAssociation = (values) => {

    return axios.post(`${process.env.REACT_APP_API_HOST}/updateStudentAssociation`, { ...values }, { withCredentials: true })
        .then(res => {
            if (res.status !== 200) return 'error'

            return 'success'
        })
        .catch(() => {
            return 'error'
        })
}

export default useEditStudentAssociation