import axios from 'axios'

const useAddOneCandidate = (values) => {

    return axios.post(`${process.env.REACT_APP_API_HOST}/addOneCandidate`, { ...values }, { withCredentials: true })
        .then(res => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export default useAddOneCandidate