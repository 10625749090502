import { Select } from '@mui/material'

const SelectBorderless = ({ children, ...props }) => {
    return (
        <Select
            sx={{
                input: {
                    border: 'none'
                },
                fieldSet: {
                    border: 'none'
                }
            }}
            {...props}>
            {children}
        </Select>
    )
}

export default SelectBorderless