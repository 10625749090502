import { useState, useEffect } from 'react'
import styles from './AddOneCandidate.module.css'
import useLocalization from '../../localization/useLocalization'
import { Card, Alert, FormControl, Stack, TextField, Container, Tooltip, Button, Divider } from '@mui/material'
import LoadingButton from '../atoms/LoadingButton'
import SelectStudentAssociation from '../molecules/SelectStudentAssociation'
import SelectElectoralDistrict from './SelectElectoralDistrict'
import SubHeader from '../atoms/SubHeader'
import useAddOneCandidate from '../../hooks/mutations/useAddOneCandidate'
import useEditCandidate from '../../hooks/mutations/useEditCandidate'
import useGetStudentAssociations from '../../hooks/queries/useGetStudentAssociations'
import useRemovePicture from '../../hooks/mutations/useRemovePicture'

const AddOneCandidate = ({ refetch, edited, setEdited }) => {
    const { data: studentAssociationData, error: studentAssociationError, loading: studentAssociationLoading } = useGetStudentAssociations()
    const localization = useLocalization()

    const [link, setLink] = useState()
    const [formValues, setFormValues] = useState({
        email: '',
        firstName: '',
        lastName: '',
        studentAssociation: '',
        electoralDistrict: '',
        school: '',
        campus: '',
        description: '',
        image: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!edited) {
            useAddOneCandidate(formValues).then(returnValue => {
                if (returnValue.data.status) {
                    setLink(
                        <Tooltip title="Copy link" placement="top">
                            <Alert severity="success" className={styles.resultItem} onClick={(event) => copyLink({ event })}>{returnValue.data.email} {returnValue.data.val}</Alert>
                        </Tooltip>
                    )
                    refetch()
                } else {
                    setLink(
                        <Alert severity="error" className={styles.resultItemErr}>{returnValue.data.val}</Alert>
                    )
                }
            })
        } else {
            useEditCandidate(formValues).then(returnValue => {
                if (returnValue.status >= 200 && returnValue.status < 300) {
                    setLink(
                        <Alert severity="success" className={styles.resultItemErr}>{localization.editSuccess}</Alert>
                    )
                    refetch()
                } else {
                    setLink(
                        <Alert severity="error" className={styles.resultItemErr}>{localization.editError}</Alert>
                    )
                }
            })
        }
        
        document.querySelector('#result').scrollIntoView({
            behavior: 'smooth'
        })

        clearForm()
    }

    const clearForm = () => {
        setFormValues({
            email: '',
            firstName: '',
            lastName: '',
            studentAssociation: '',
            electoralDistrict: '',
            school: '',
            campus: '',
            description: '',
            image: ''
        })
        setEdited(null)
    }

    const copyLink = (e) => {
        let text = e.event.target.innerHTML
        if (text) {
            text = text.split('}')[1].trim()
            navigator.clipboard.writeText(text)
        }
    }

    const deletePic = () => {
        useRemovePicture(formValues)
            .then(returnValue => {
                if (returnValue.data.status) {
                    setLink(
                        <Alert severity="success" className={styles.resultItemBasic}>{returnValue.data.value}</Alert>
                    )
                    setFormValues({
                        ...formValues,
                        image: '/auto.png',
                    })
                    refetch()
                } else {
                    setLink(
                        <Alert severity="error" className={styles.resultItemErr}>{returnValue.data.value}</Alert>
                    )
                }
            }).catch(() => {
                setLink(
                    <Alert severity="error" className={styles.resultItemErr}>{localization.editError}</Alert>
                )
            })
    }

    useEffect(() => {
        if(edited) {
            setFormValues({
                email: edited.email || '',
                firstName: edited.firstName || '',
                lastName: edited.lastName || '',
                studentAssociation: edited.studentAssociation?._id || '',
                electoralDistrict: edited.electoralDistrict || '',
                school: edited.school || '',
                campus: edited.campus || '',
                description: edited.description || '',
                image: edited.image || ''
            })

            window.scrollTo({
                top: 750,
                left: 0,
                behavior: 'smooth'
            })
        }
    }, [edited])

    return (
        <Card className={styles.mainContainer}>
            <SubHeader headingText={!edited ? localization.addCandidate : localization.editCandidate} variant={'h6'} />
            <form onSubmit={handleSubmit} autoComplete="off" className={styles.secondaryContainer}>
                <Stack direction="column" spacing={2} sx={{ marginTop: '1em' }}>
                    <FormControl>
                        <TextField name="email" type="email" value={formValues.email} label={localization.emailPlaceHolder} fullWidth onChange={handleChange} required />
                    </FormControl>
                    <FormControl>
                        <TextField name="firstName" value={formValues.firstName} label={localization.firstName} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="lastName" value={formValues.lastName} label={localization.lastName} fullWidth onChange={handleChange} />
                    </FormControl>
                    <SelectStudentAssociation name="studentAssociation" data={studentAssociationData} loading={studentAssociationLoading} error={studentAssociationError} value={formValues.studentAssociation} onChange={ (_id, _sa, e) => handleChange(e) }/>
                    <SelectElectoralDistrict name="electoralDistrict"  data={studentAssociationData} loading={studentAssociationLoading} error={studentAssociationError} selectedStudentAssociationId={formValues.studentAssociation} value={formValues.electoralDistrict} label={localization.electoralDistrict} onChange={ (_id, _sa, e) => handleChange(e) } />
                    <FormControl>
                        <TextField name="school" value={formValues.school} label={localization.school} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="campus" value={formValues.campus} label={localization.campus} fullWidth onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                        <TextField name="description" multiline value={formValues.description} label={localization.description} fullWidth onChange={handleChange} />
                    </FormControl>
                    {
                        formValues.image &&
                        <Stack direction="row" spacing={1} className={styles.deletePic}>
                            <img src={formValues.image} alt='' width='100px' height='100px'/>
                            <Button variant="outlined" type="button" color="error" onClick={() => deletePic()} sx={{ width: '100px', height: '30px' }}>{localization.resetCandidateImage}</Button>  
                        </Stack>
                    }
                    <Stack direction="row" spacing={2} >
                        <LoadingButton loading={false} variant="contained" color="primary" type="submit" sx={{ width: '100%'  }}>
                            { !edited ? localization.fillFormButton : localization.updateButton }
                        </LoadingButton>
                        <Button variant="outlined" type="button" onClick={() => clearForm()} sx={{ width: '100%'  }}>{edited ? localization.cancelButton : localization.clearButton}</Button>
                    </Stack>
                </Stack>
            </form>
            <Container id="result" className={styles.resultContainer}>
                <SubHeader headingText={localization.results} variant={'h6'} />
                <Divider variant="middle" sx={{ marginTop: '10px' }} />
                {link}
            </Container>
        </Card>
    )
}

export default AddOneCandidate