import { useState } from 'react'
import styles from './ResetCandidatePassword.module.css'
import useLocalization from '../../localization/useLocalization'
import { Alert, FormControl, Stack, TextField, Card, Container, Divider, Tooltip } from '@mui/material'
import LoadingButton from '../atoms/LoadingButton'
import useResetPassword from '../../hooks/mutations/useResetPassword'
import SubHeader from '../atoms/SubHeader'

const ResetCandidatePassword = () => {
    const localization = useLocalization()
    const [link, setLink] = useState()
    const [formValues, setFormValues] = useState({
        email: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        useResetPassword(formValues)
            .then(returnValue => {
                if (returnValue.data.status) {
                    setLink(
                        <Tooltip title="Copy link" placement="top">
                            <Alert severity="success" className={styles.resultItem} onClick={(event) => copyLink({ event })}>{returnValue.data.email} {returnValue.data.val}</Alert>
                        </Tooltip>
                    )
                    setFormValues({
                        ...formValues,
                        email: '',
                    })
                } else {
                    setLink(
                        <Alert severity="error" className={styles.resultItemErr}>{returnValue.data.val}</Alert>
                    )
                }
            }).catch(() => {
                setLink(
                    <Alert severity="error" className={styles.resultItemErr}>{localization.editError}</Alert>
                )
            })
    }

    const copyLink = (e) => {
        let text = e.event.target.innerHTML
        if (text) {
            text = text.split('}')[1].trim()
            navigator.clipboard.writeText(text)
        }
    }

    return (
        <Card className={styles.mainContainer}>
            <form onSubmit={handleSubmit} autoComplete="off" style={{ width:'100%' }}>
                <SubHeader headingText={localization.resetCandidatePassword} variant={'h6'} />
                <Stack direction="row" spacing={2} className={styles.secondaryContainer}>
                    <FormControl sx={{ width: '90%' }}>
                        <TextField name="email" value={formValues.email} label={localization.emailPlaceHolder} fullWidth onChange={handleChange} />
                    </FormControl>
                    <LoadingButton loading={false} variant="contained" color="primary" type="submit" >
                        { localization.fillFormButton }
                    </LoadingButton>
                </Stack>
            </form>
            <Container id="result3" className={styles.resultContainer}>
                <SubHeader headingText={localization.results} variant={'h6'} />
                <Divider variant="middle" sx={{ marginTop: '10px' }} />
                {link}
            </Container>
        </Card>
    )
}

export default ResetCandidatePassword