import { useEffect, useState } from 'react'
import axios from 'axios'
import useUser from '../user/useUser'
import useChangeTheme from '../useChangeTheme'

const useGetProfile = () => {
    const changeTheme = useChangeTheme()
    const [data, setData] = useState()
    const [error, setError] = useState()
    const user = useUser() 

    const fetchProfile = () => {
        axios.post(`${process.env.REACT_APP_API_HOST}/getProfile`, { email : user.email }, { withCredentials: true })
            .then((res) => {
                setData(res.data)
                const sa = res.data.studentAssociation
                changeTheme(sa)
            })
            .catch((err) => {
                setError(err)
            })
    }

    useEffect(() => {
        fetchProfile()
    }, [])

    return { data, error, loading: !error && !data, refetch: fetchProfile }
}

export default useGetProfile