import { FormControl, MenuItem, Select, InputLabel, Skeleton, IconButton } from '@mui/material'
import useLocalization from '../../localization/useLocalization'
import ClearIcon from '@mui/icons-material/Clear'

const SelectElectoralDistrict = ({ data, error, loading, selectedStudentAssociationId, onChange, value, name, clearable }) => {
    const localization = useLocalization()

    const onValueChanged = (e) => {
        const id = e.target.value
        const districts = electoralDistricts.find(d => d._id === id)
        onChange(id, districts, e)
    }

    const clearSelection = () => {
        onChange(null)
    }

    if (loading) return <Skeleton width="100%" height="3em" />
    if (error) return <div>{error}</div>

    const selectedStudentAssociation = data.find(sa => sa._id === selectedStudentAssociationId)
    const electoralDistricts = selectedStudentAssociation?.electoralDistricts || []
    const clearButton = <IconButton sx={{ display: value? '': 'none' }} onClick={clearSelection}><ClearIcon/></IconButton>

    return (
        <FormControl sx={{ margin: { xs: '1em', lg: '0' }, width: { xs: '90%', lg: '100%' } }}>
            <InputLabel id="select-label">{localization.electoralDistrict}</InputLabel>
            <Select
                disabled={!selectedStudentAssociationId}
                labelId="select-label"
                name={name}
                value={value ? value : ''}
                onChange={onValueChanged}
                label={'Valitse vaalipiiri'}
                endAdornment={ clearable ? clearButton : null}
                sx={{
                    '& .MuiSelect-iconOutlined': { display: clearable && value? 'none': '' }
                }}
            >
                {
                    electoralDistricts.map(x => {
                        return <MenuItem key={x._id} value={x._id}>{x.name}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}

export default SelectElectoralDistrict