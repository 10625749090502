import { useState, useEffect } from 'react'
import axios from 'axios'

const useGetQuestions = () => {
    const [data, setData] = useState()
    const [error, setError] = useState()

    const fetchQuestions = () => {
        axios.get(`${process.env.REACT_APP_API_HOST}/allQuestions`, { withCredentials: true })
            .then(res  => {
                if (res.data) return setData(res.data)
                throw new Error()
            })
            .catch(err => {
                if (err?.response?.data) return setError(err.response.data)
                setError('Something went wrong...')
            })
    }

    useEffect(() => {
        fetchQuestions()
    }, [])
    
    return { data, error, loading: !error && !data, refetch: fetchQuestions }
}

export default useGetQuestions