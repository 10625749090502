import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import axios from 'axios'
import refreshUserJWT from './utils/refreshUserJWT'
import clearUser from './utils/clearUser'

axios.interceptors.response.use((res) => {
    refreshUserJWT()
    return res
}, (err) => {
    if (err.response?.status === 401) return clearUser()
    return Promise.reject(err)
})

const root = ReactDOM.createRoot(
    document.getElementById('root')
)
root.render(<App />)