import axios from 'axios'

const useResetPassword = (values) => {
    return axios.post(`${process.env.REACT_APP_API_HOST}/resetPassword`, { ...values }, { withCredentials: true })
        .then(res => {        
            return res
        })
        .catch((err) => {
            return err
        })
}

export default useResetPassword