import { useEffect, useState } from 'react'
import axios from 'axios'

const useGetAdmins = () => {
    const [data, setData] = useState()
    const [error, setError] = useState()
    const fetch = () => {
        axios.get(`${process.env.REACT_APP_API_HOST}/admins`, { withCredentials: true })
            .then(res => {
                if (res.data) return setData(res.data)
                throw new Error()
            })
            .catch(err => {
                if (err?.response?.data) return setError(err.response.data)
                setError('Something went wrong...')
            })
    }
    useEffect(() => {
        fetch()
    }, [])

    return { data, error, loading: !error && !data, refetch: fetch }
}

export default useGetAdmins