import { Skeleton, FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import useLocalization from '../../localization/useLocalization'

const SelectStudentAssociation = ({ onChange, value, name, data, error, loading, required, fieldError }) => {
    const localization = useLocalization()

    const onValueChanged = (e) => {
        const id = e.target.value
        const studentAssociation = data.find(sa => sa._id === id)
        onChange(id, studentAssociation, e)
    }

    if (loading) return <Skeleton width="100%" height="3em" />
    if (error) return <div>{error}</div>
    if (!data) return <div>Datan hakemisessa tapahtui virhe</div>

    return (
        <FormControl fullWidth required={required}>
            <InputLabel id="select-label">{localization.selectStudentAssociation}</InputLabel>
            <Select
                labelId="select-label"
                name={name}
                value={value}
                error={fieldError}
                onChange={onValueChanged}
                label={localization.selectStudentAssociation}
            >
                {
                    data.map(x => {
                        return <MenuItem key={x._id} value={x._id}>{x.name}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}

export default SelectStudentAssociation