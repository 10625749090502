import getCapitalizedLanguage from '../../localization/getCapitalizedLanguage'
import { Typography } from '@mui/material'
import styles from './QuestionText.module.css'

const QuestionText = ({ question, sx }) => {
    return (
        <Typography sx={sx} className={styles.questionText}>{`" ${question['question' + getCapitalizedLanguage()]} "`}</Typography>
    )
}

export default QuestionText