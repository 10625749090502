import { useState, useEffect } from 'react'
import axios from 'axios'

const useGetFilteredQuestions = (studentAssociation) => {
    const [data, setData] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_HOST}/filteredQuestions`, { data: studentAssociation?._id }, { withCredentials: true })
            .then(res => {
                if (res.data) return setData(res.data)
                throw new Error()
            })
            .catch(err => {
                if (err?.response?.data) return setError(err.response.data)
                setError('Something went wrong...')
            })
    }, [studentAssociation?._id])
    
    return { data, error, loading: !error && !data }
}

export default useGetFilteredQuestions