import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

export default function SubHeader({ headingText, variant }) {

    return (
        <Container sx={{ marginTop: '2%' }} >
            <Typography variant={variant} component="div" className=''>
                {headingText}
            </Typography>
        </Container>
    )
}