const language = {
    title: 'Valkompass',
    homePageDescription: 'Välkommen att använda valkompassen',
    homePageInfo: 'Börja med att använda din studentkår',
    selectStudentAssociation: 'Studerandekår',
    navigationHome: 'Hem',
    login: 'Logga in',
    navigationRegister: ' Registrering',
    navigationLogOut: 'Logga ut',
    navigationProfile: ' Profil',
    formButton: 'Frågor',
    loginButton: 'Inlogging',
    loginHolder: 'Inloggningen lyckades',
    mainPageLink: 'Tillbaka till framsidan',
    registerButton: 'Registrering',
    emailPlaceHolder: 'E-postadress',
    passwordPlaceHolder: ' Lösenord',
    passwordConfirmationPlaceHolder: ' Lösenordsverifiering',
    registrationError: 'Du kan inte registrera dig med en e-postadress som inte har lagts till i databasen som kandidat!',
    passwordConfirmationError: 'Det verifierade lösenordet måste matcha lösenordet',
    profileButton: 'Skicka information',
    adminLogo: 'Adminpanel',
    addCandidatesCautionHolder: 'Detta kommer att lägga till alla kandidater som inte finns i databasen',
    profileUpdateError: 'Det gick inte att uppdatera profilen',
    profileUpdateSuccess: 'Du har uppdaterat din profilinformation',
    fillFormButton: 'Skicka',
    continueHolder: 'Fortsätt',
    browseButton: 'Bläddra',
    uploadPicture: 'Ladda upp profilbild',
    candidateHolder: 'Kandidat',
    successQuestionAddedHolder: 'Du har lagt till en ny fråga',
    wrongPasswordHolder: 'Fel användarnamn eller lösenord',
    addedCandidatesToDatabaseAlert: 'Kandidater har lagts till i databasen',
    addingOneCandidateToDatabaseAlert: 'Kandidat har lagts till i databasen',
    addingOneCandidateToDatabaseErrorAlert: 'En kandidat med den här e-postadressen existerar redan',
    filledFormAlert: 'Du har fyllt i formuläret',
    oneCandidate: 'Fyll i informationen nedan för att lägga till en kandidat',
    manyCandidates: ' Du kan lägga till fler kandidater via länken nedan. Endast .csv/.txt -filer fungerar',
    addCandidates: 'Lägg till flera kandidater',
    addCandidate: 'Lägg till en kandidat',
    addAdmin: 'Lägg till admin',
    browseCandidates: 'Bläddra kandidater',
    addQuestion: 'Lägg till en fråga',
    browseQuestions: 'Bläddra frågor',
    removeButton: 'Ta bort',
    agreeButton: 'Samma åsikt',
    disagreeButton: 'Annan åsikt',
    matchingCandidates: ' Kandidater med liknande åsikt',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    school: 'Högskola',
    selectStudentAssociationList: 'Studerandekår',
    campus: 'Campus',
    electoralDistrict: 'Valcirkel',
    electoralAlliance: 'Valförbund',
    description: 'Beskrivning',
    addQuestionButton: 'Lägg till fråga',
    deleteButton: 'Ta bort',
    editButton: ' Redigera',
    updateButton: 'Uppdatera information',
    formLink: 'Länk till ditt ifyllda formulär',
    studentAssociationHolder: 'Studerandekår',
    deleteHolder: 'Ta bort',
    questionHolder: ' Fråga:',
    noSimilarCandidates: ' Tyvärr var det ingen som var av samma åsikt som dig',
    editStudentAssociation: 'Redigera studerandekåren',
    createStudentAssociation: 'Lägg till studerandekår',
    createNew: 'Lägg till ny',
    studentAssociationSuccess: 'Bearbetning av studerandekår lyckades',
    studentAssociationError: 'Bearbetning av studerandekår misslyckades',
    answerUpdateSuccess: 'Uppdatering av svar lyckades',
    answerUpdateError: 'Uppdatering av svar misslyckades',
    agree: 'Håller med',
    neutral: 'Jag kan inte säga',
    disagree: 'Håller inte med',
    clearButton: 'Töm',
    cancelButton: 'Avbryt',
    candidates: 'Kandidater',
    deleteSelected: 'Ta bort valda',
    editSelected: 'Redigera valda',
    editSuccess: 'Uppdatering av data lyckades',
    editError: 'Ett fel uppstod vid uppdatering av data!',
    candidateControls: 'Kandidathantering',
    schoolControls: 'Högskolehantering',
    questionControls: 'Frågehantering',
    adminControls: 'Adminhantering',
    studentAssociations: 'Studerandekårer',
    questions: 'Frågor',
    scale: 'Skala',
    sequence: 'Ordning',
    editCandidate: 'Redigera kandidat',
    editQuestion: 'Redigera fråga',
    results: 'Resultat',
    copyLink: 'Kopiera länk',
    selectFileToUpload: 'Välj fil',
    questionFin: 'Fråga på finska',
    questionEng: 'Fråga på engelska',
    questionSwe: 'Fråga på svenska',
    itemDeleteSuccess: 'Valda objekt har raderats',
    itemDeleteError: ' Problem med att ta bort valda objekt!',
    resetPassword: 'Ange nytt lösenord',
    resetCandidatePassword: 'Ange lösenord för kandidat',
    candidate: 'Kandidat',
    you: 'Du',
    profileFillWarning: 'Profilen saknar viktig information',
    imageUploadFailed: 'Det gick inte att spara bilden',
    fileUploadPrompt: 'Dra en bild hit eller klicka här för att välja en bild',
    uploadErrorSupportedFormats: 'Bilduppladdning misslyckades. Bilden måste vara .jpeg, .jpg, .gif eller .png',
    answerQuestions: 'Svara på enkäten',
    fillRequiredBeforeAnswering: 'Fyll i den obligatoriska profilinformationen innan du kan svara på frågorna',
    profileRequiredMissingRedirect: 'Du omdirigerades tillbaka till din profil eftersom några av de obligatoriska fälten ännu inte är ifyllda',
    returnToQuestions: 'Tillbaka till enkäten',
    returnToFront: 'Tillbaka till framsidan',
    returnToProfile: 'Tillbaka till profilsidan',
    answerAndSend: 'Svara på frågorna och skicka in formuläret',
    labelColorPicker: 'Temafärg (hex)',
    studentAssociationLogoLabel: 'Studerandekårens logo',
    studentAssociationNameLabel: 'Studerandekårens namn',
    studentAssociationColorLabel: 'Studerandekårens temafärg',
    hexColorFormatOnly: 'Färgen måste vara i hex-format',
    noElectoralDistrictSet: 'Valområde inte valt',
    profilePictureLabel: 'Profilbild',
    admins: 'Administratörer',
    passwordResetFailed: 'Lösenordsuppdatering misslyckades',
    resetCandidateImage: 'PUUTTUU',
    profileImageUploaded: 'PUUTTUU',
}

export default language