import { useState } from 'react'
import axios from 'axios'
import useUser from '../user/useUser'

const useGetProfileLazy = () => {
    const [data, setData] = useState()
    const [error, setError] = useState()
    const user = useUser()

    const fetchProfile = () => {
        return axios.post(`${process.env.REACT_APP_API_HOST}/getProfile`, { email : user.email }, { withCredentials: true })
            .then((res) => {
                setData(res.data)
                return res.data
            })
            .catch((err) => {
                setError(err)
                return err
            })
    }

    return { data, error, loading: !error && !data, fetch: fetchProfile }
}

export default useGetProfileLazy