import { Container, List, ListItem, Paper, Typography, useMediaQuery } from '@mui/material'
import styles from './CandidateAnswers.module.css'
import useLocalization from '../../localization/useLocalization'
import QuestionText from '../atoms/QuestionText'
import { useContext } from 'react'
import ThemeContext from '../../contexts/ThemeContext'
import color from 'color'
import TruncateTypography from '../atoms/TruncateTypography'

const CandidateAnswers = ({ candidateAnswers, userAnswers }) => {
    const localization = useLocalization()
    const sortedCandidateAnswers = candidateAnswers.sort((a, b) => a.question.sequence - b.question.sequence)
    const { theme } = useContext(ThemeContext)
    const isMobile = useMediaQuery('(max-width:700px)')

    const mainColor = theme?.palette?.primary?.main || '#1976d2'
    const luminosityDiff = Math.abs(color(mainColor).luminosity() - color(mainColor).rotate(180).luminosity())
    const shouldUseDefault = luminosityDiff < 0.01
    const userColor = color(mainColor).toString()
    const candidateColor = shouldUseDefault ? '#1976d2' : color(mainColor).rotate(180).toString()
    const borderColor = color(candidateColor).fade(0.7).toString()
    const backgroundColor = color(candidateColor).fade(0.9).toString()
    const combinedColor = `linear-gradient( -45deg, ${candidateColor}, ${candidateColor} 49%, #fafafa 49%, #fafafa 51%, ${userColor} 51% )`

    const descriptionSx = {
        fontStyle: 'italic',
        fontWeight: 'lighter',
        padding: '0.5em',
        border: `1px solid ${borderColor}`,
        borderRadius: '1em',
        backgroundColor: backgroundColor,
        margin: '0 1em 1em'
    }

    const getMarkerLineOffsets = (scale) => {
        const offsets = {
            3: ['0%', '50%', '100%'],
            5: ['0%', '25%', '50%', '75%', '100%'],
            7: ['0%', '16.6%', '33.3%', '50%', '66.6%', '83.3%', '100%'],
        }
        if (!Object.keys(offsets).includes(scale.toString())) return []
        return offsets[scale]
    }
    const getDotOffset = (answer, scale) => {
        const offsets = {
            3: {
                '-10': '0%',
                0: '50%',
                10: '100%',
            },
            5: {
                '-8': '0%',
                '-4': '25%',
                0: '50%',
                4: '75%',
                8: '100%',
            },
            7: {
                '-12': '0%',
                '-8': '16.6%',
                '-4': '33.3%',
                0: '50%',
                4: '66.6%',
                8: '83.3%',
                12: '100%',
            },
        }
        return offsets[scale][answer]
    }

    const getDots = (item) => {
        const userAnswer = userAnswers.find(a => a.id === item.question._id)

        if (item.value === userAnswer.value) {
            const combinedDot = <span key={`${item.question.sequence}${item.value}`} style={{ left: getDotOffset(item.value, item.question.scale), background: combinedColor }} className={styles.dot} />
            return [combinedDot]
        } else {
            const candidateDot = <span key={`${item.question.sequence}${item.value}`} style={{ left: getDotOffset(item.value, item.question.scale), backgroundColor: candidateColor }} className={styles.dot} />
            const userDot = <span key={`${item.question.sequence}${userAnswer.value}`} style={{ left: getDotOffset(userAnswer.value, item.question.scale), backgroundColor: userColor }} className={styles.dot} />
            return [candidateDot, userDot]
        }
    }

    return (
        <>
            <Container sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <Typography>{localization.candidate} <span style={{ backgroundColor: candidateColor }} className={styles.exampleDot}></span></Typography>
                <Typography>{localization.you} <span style={{ backgroundColor: userColor }} className={styles.exampleDot}></span></Typography>
            </Container>
            <List sx={{ margin: '0 auto', width: { lg: '90%', xs: '100%' } }}>
                { sortedCandidateAnswers.map((answer) => {
                    const dots = getDots(answer)
                    const markerLineOffsets = getMarkerLineOffsets(answer?.question?.scale)
                    return (
                        <ListItem key={answer._id} sx={{ padding: { xs: '8px 0 !important' } }}>
                            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: '#fff', padding: { xs: '1em 0',lg: '1em !important' }, borderRadius: '1em', width: '100%' }}>
                                <Container className={styles.centeredContainer} sx={{ marginBottom: '1em' }}>
                                    <QuestionText sx={{ textAlign: 'center', marginBottom: '1em' }} question={answer.question} />
                                    <Container sx={{ width: { xs: '75%' }, padding: '0 !important' }}>
                                        <div className={styles.sliderContainer}>
                                            <span className={styles.line} style={{ borderColor: mainColor }}/>
                                            { markerLineOffsets.map((offset, index) => <span key={`${offset}${index}`} style={{ left: offset, borderColor: mainColor }} className={styles.verticalLine} /> )}
                                            {dots}
                                        </div>
                                        <div className={styles.sliderTexts}>
                                            <Typography sx={{ left: '0%' }}>{localization.disagree}</Typography>
                                            <Typography sx={{ left: '50%' }}>{localization.neutral}</Typography>
                                            <Typography sx={{ left: '100%' }}>{localization.agree}</Typography>
                                        </div>
                                    </Container>
                                </Container>
                                { isMobile
                                    ?  answer?.description?.length > 0 && <TruncateTypography showLength={100} align="center" variant="body1" sx={descriptionSx}>{answer.description}</TruncateTypography>
                                    : answer?.description?.length > 0 && <Typography align="center" variant="body1"  sx={descriptionSx}>{answer.description}</Typography>
                                }
                            </Paper>
                        </ListItem>
                    )
                })}
            </List>
        </>

    )
}

export default CandidateAnswers