import axios from 'axios'

const useEditCandidate = (values) => {

    return axios.post(`${process.env.REACT_APP_API_HOST}/updateProfile`, { data: values }, { withCredentials: true })
        .then(res => {
            // if (res.status !== 201) return res
                
            return res
        })
        .catch((err) => {
            return err
        })
}

export default useEditCandidate