import axios from 'axios'

const useAddManyCandidates = (values) => {
    return axios.post(`${process.env.REACT_APP_API_HOST}/addCandidates`, { data: values }, { withCredentials: true })
        .then(res => {
            if (res.status > 201) return 'error'
                
            return res
        })
        .catch((err) => {
            return err
        })
}

export default useAddManyCandidates