import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useLocalization from '../../localization/useLocalization'
import { languages } from '../../constants'
import useIsLoggedIn from '../../hooks/user/useIsLoggedIn'
import useLogout from '../../hooks/mutations/useLogout'
import LanguagePicker from '../molecules/LanguagePicker'
import { Link } from 'react-router-dom'
import ConditionalNavigationOptions from '../molecules/ConditionalNavigationOptions'
import { useContext } from 'react'
import ThemeContext from '../../contexts/ThemeContext'
import { Stack } from '@mui/material'

export default function NavBar() {
    const themeContext = useContext(ThemeContext)
    const local = useLocalization(languages.swe)
    const logout = useLogout()
    const loggedIn = useIsLoggedIn()
    const logo = themeContext.theme?.image ? <img height={62} src={themeContext.theme.image}></img> : <></>
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Link to="/" style={{ flexGrow: 1 }}>
                        <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <Typography variant="h6" component="div">
                                { local.title }
                            </Typography>
                            {logo}
                        </Stack>

                    </Link>
                    <LanguagePicker />
                    <ConditionalNavigationOptions />
                    { loggedIn ? (
                        <Link to="/">
                            <Button color="inherit" onClick={logout}>{local.navigationLogOut}</Button>
                        </Link>
                    ):(
                        <Link to="/login">
                            <Button color="inherit">{local.login}</Button>
                        </Link>
                    )
                    }
                </Toolbar>
            </AppBar>
        </Box>
    )
}
