import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'

const LoadingButton = ({ loading, children, ...props }) => {
    return (
        <Button { ...props }>
            { loading && <CircularProgress sx={{ color: '#fff' }} size={14} /> }
            { !loading && children }
        </Button>
    )
}

export default LoadingButton