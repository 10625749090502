import useLocalization from './localization/useLocalization'

const localization = useLocalization()

export const supportedScales = [3, 5, 7]

export const marks = {
    scale7: [
        {
            value: -12,
            label: localization.disagree,
        },
        {
            value: -8,
            label: '',
        },
        {
            value: -4,
            label: '',
        },
        {
            value: 0,
            label: localization.neutral,
        },
        {
            value: 4,
            label: '',
        },
        {
            value: 8,
            label: '',
        },
        {
            value: 12,
            label: localization.agree,
        },
    ],
    scale5: [
        {
            value: -8,
            label: localization.disagree || '',
        },
        {
            value: -4,
            label: '',
        },
        {
            value: 0,
            label: localization.neutral  || '',
        },
        {
            value: 4,
            label: '',
        },
        {
            value: 8,
            label: localization.agree  || '',
        },
    ],
    scale3: [
        {
            value: -10,
            label: localization.disagree  || '',
        },
        {
            value: 0,
            label: localization.neutral  || '',
        },
        {
            value: 10,
            label: localization.agree  || '',
        },
    ]
}

export const defaults = {
    scale7: {
        min: -12,
        max: 12,
        default: 0
    },
    scale5: {
        min: -8,
        max: 8,
        default: 0
    },
    scale3: {
        min: -10,
        max: 10,
        default: 0
    }
}