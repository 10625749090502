import { defaults, marks } from '../../scaleSetup'
import { Slider } from '@mui/material'

const QuestionSlider = ({ handleChange, value, question }) => {
    
    return (
        <Slider
            aria-label="Restricted values"
            min={defaults['scale' + question.scale].min}
            max={defaults['scale' + question.scale].max}
            track={false}
            step={null}
            valueLabelDisplay="off"
            marks={marks['scale' + question.scale]}
            value={value}
            onChange={(e) => handleChange(question._id, e.target.value, 'val')}
            sx={{
                '.MuiSlider-mark': {
                    height: '9px',
                    width: '2px',
                }
            }}
        />
    )
}

export default QuestionSlider