import * as React from 'react'
import { Card, CardActions, CardContent, Box, TextField }from '@mui/material'
import styles from './UserQuestionItem.module.css'
import useLocalization from '../../localization/useLocalization'
import QuestionText from '../atoms/QuestionText'
import QuestionSlider from '../atoms/QuestionSlider'

export default function CandidateQuestionItem({ question, handleChange, value, description }) {
    const localization = useLocalization()

    const handleValChange = (id, newValue, label) => {
        if(label === 'val') {
            handleChange(id, newValue, description)

        } else if (label === 'desc') {
            handleChange(id, value, newValue)
        }
    }

   
    return (
        <Card id={question._id} className={styles.questionBlock}>
            <CardContent>
                <QuestionText question={question} />
            </CardContent>
            <CardActions>
                <Box className={styles.sliderBlock}>
                    <QuestionSlider question={question} value={value} handleChange={handleValChange} />
                </Box>
            </CardActions>
            <CardContent>
                <TextField id="desc-field" multiline label={localization.description} variant="outlined" value={description} onChange={(e) => handleValChange(question._id, e.target.value,'desc')} sx={{ width: '90%' }} />
            </CardContent>
        </Card>
    )
}
