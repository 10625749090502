import { useState } from 'react'
import { Container } from '@mui/material'
import QuestionTable from '../molecules/QuestionTable'
import useGetQuestions from '../../hooks/queries/useGetQuestions'
import AddOneQuestion from '../molecules/AddOneQuestion'

const QuestionControls = () => {
    const { data, error, loading, refetch } = useGetQuestions()
    const [edited, setEdited] = useState(null)

    return (
        <Container sx={{ width: '100%', marginTop:'5%', marginBottom:'5%' }}>
            <QuestionTable data={data} error={error} loading={loading} refetch={refetch} setEdited={setEdited} />
            <AddOneQuestion data={data} refetch={refetch} edited={edited} setEdited={setEdited} />
        </Container>
    )
}

export default QuestionControls