import styles from './Admin.module.css'
import useLocalization from '../localization/useLocalization'
import { Card, Container, Tab, Tabs } from '@mui/material'
import { Outlet, Link, useLocation } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import useChangeTheme from '../hooks/useChangeTheme'
import { useEffect } from 'react'

const AdminPage = () => {
    const location = useLocation()
    const currentSubPage = location.pathname.split('/').pop()
    const localization = useLocalization()
    const changeTheme = useChangeTheme()

    const routeToValueMap = {
        candidates: 0,
        studentassociations: 1,
        questions: 2,
        admins: 3,
    }

    useEffect(() => {
        changeTheme()
    }, [])

    return (
        <Card className={styles.mainContainer}>
            <Tabs
                className={styles.header}
                value={routeToValueMap[currentSubPage]}
                centered
            >
                <Tab icon={<PersonIcon />} iconPosition="start" label={localization.candidateControls} sx={{ width: '100%' }} to="candidates" component={Link} />
                <Tab icon={<SchoolIcon />} iconPosition="start" label={localization.schoolControls} sx={{ width: '100%' }} to="studentassociations" component={Link} />
                <Tab icon={<ArticleOutlinedIcon />} iconPosition="start" label={localization.questionControls} sx={{ width: '100%' }} to="questions" component={Link} />
                <Tab icon={<ManageAccountsIcon />} iconPosition="start" label={localization.adminControls} sx={{ width: '100%' }} to="admins" component={Link} />
            </Tabs>
            <Container className={styles.sectionContainer}>
                <Outlet />
            </Container>
        </Card>
    )
}

export default AdminPage