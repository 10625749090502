export const languages = {
    fin: 'fin',
    swe: 'swe',
    eng: 'eng'
}
export const roles = {
    candidate: 'Candidate',
    admin: 'Admin'
}
export const alertLength = 5000
export const userToken = 'token'
export const languageToken = 'language'
