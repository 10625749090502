import * as React from 'react'
import { Card, CardActions, CardContent, Box } from '@mui/material'
import styles from './UserQuestionItem.module.css'
import QuestionText from '../atoms/QuestionText'
import QuestionSlider from '../atoms/QuestionSlider'

export default function UserQuestionItem({ question, handleChange, value }) {
   
    return (
        <Card id={question._id} className={styles.questionBlock}>
            <CardContent>
                <QuestionText question={question} />
            </CardContent>
            <CardActions>
                <Box className={styles.sliderBlock}>
                    <QuestionSlider value={value} question={question} handleChange={handleChange}/>
                </Box>
            </CardActions>
        </Card>
    )
}
